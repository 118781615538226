import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "@reach/router";
import useTranslations from "../../hooks/useTranslations";
import { ProductsContext } from "../../context/ProductsContext";
import ProductsTable from "../../components/products/ProdutcsTable";
import { OrganizationsContext } from "../../context/OrganizationsContext";

const AdminProducts = () => {
  const [sort, setSort] = useState("name");
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [direction, setDirection] = useState("ASC");

  const location = useLocation();

  const {organization} = useContext(OrganizationsContext);

  const { products, getAllProducts } = useContext(ProductsContext);

  const translations = useTranslations();
  const usersTranslations = translations.admin.users;

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  const fetchProducts = () => {
    const params = {
      query,
      page,
      sort,
      direction,
    };
    if (location.pathname.includes("organization")) {
      if (!organization && organization === null) {
        return;
      }
      if (organization.organization_id !== null) {
        params.organization_id = organization.organization_id;
      }
    }
    getAllProducts(params);
  };

  useEffect(() => {
    if (query.length > 0) {
      const timeoutId = setTimeout(fetchProducts, 500);
      return () => clearTimeout(timeoutId);
    } else {
      fetchProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page, sort, direction]);

  const renderProducts = () => {
    if (Array.isArray(products)) {
      let productsRender = [...products];
      if (organization && organization !== null) {
        if (organization.organization_id !== null) {
          productsRender = productsRender.filter(
            (product) =>
              product.organization_id === organization.organization_id
          );
        }
      }
      return (
        <ProductsTable
          products={productsRender}
          setSort={setSort}
          direction={direction}
          selected={sort}
          setDirection={setDirection}
        />
      );
    }
  };

  return (
    <div className="container-fluid h-100 d-flex flex-column">
      <div className="row mx-0 align-items-center mb-3 border-bottom">
        <div className="col-12 px-0">
          <h1 className="h3 bold text-gradient">{organization?.organization_id !== null ? organization.name + " ": ""}Products</h1>
        </div>
        <div className="col-12 px-0 align-items-center">
          <div className="row">
            <div className="col-12 col-lg-8 mb-3 px-0">
              <div className="row">
                <input
                  type="text"
                  className="form-control bg-white"
                  placeholder="Search..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
            <div
              className="col-12 px-0 ps-lg-3 mb-4 col-lg-4 text-right"
              style={{ minWidth: "145px" }}
            >
              <Link
                to={
                  organization?.organization_id && organization?.organization_id !== null
                    ? "/organization/admin/products/new"
                    : "new/edit"
                }
                className="btn btn-primary w-100"
              >
                + {usersTranslations.addBtn}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div style={{ overflowY: "auto" }}>
        {renderProducts()}
      </div>
    </div>
  );
};

export default AdminProducts;
