const conversation = {
  header: {
    edit: "Editar Chat",
  },
  max_messages: "No hay más mensajes",
  continue_generating: "Continuar generando",
  form: {
    name: "Nombre",
  },
  separate_commas: "Separar con comas",
  message: {
    edit: "Editar Respuesta",
    save: "Guardar Respuesta",
    copy: "Copiar al portapapeles",
    pending: "Generando respuesta...",
    append_avatar: "Agregar a Avatar",
  },
  input: {
    placeholder: "Escribe una instrucción",
    enhance: "Mejorar",
    send: "Enviar",
    templates: {
      button: "Plantillas",
      title: "Plantillas",
      cancel: "Cancelar",
    },
  },
};

export default conversation;
