import React, { useContext, useState, useEffect } from "react";
import Auth from "./views/Auth";
import Panel from "./views/Panel";
import { getValue } from "./utils";
import Favicon from "react-favicon";
import Landing from "./views/Landing";
import useSocket from "./hooks/useSocket";
import Walkthrough from "./views/Walkthrough";
import Modal from "./components/global/Modal";
import { handleURLParams } from "./utils/auth";
import { Router, navigate } from "@reach/router";
import LandingSpanish from "./views/LandingSpanish";
import { AuthContext } from "./context/AuthContext";
import LoginForm from "./components/auth/LoginForm";
import { ModalContext } from "./context/ModalContext";
import useTranslations from "./hooks/useTranslations";
import useOrganization from "./hooks/useOrganization";
import ErrorAlert from "./components/common/ErrorAlert";
import { SocketContext } from "./context/SocketContext";
import FirebaseService from "./services/FirebaseService";
import IntroVideo from "./components/welcome/IntroVideo";
import SuccessAlert from "./components/common/SuccessAlert";
import { MessagesContext } from "./context/MessagesContext";
import { CapacitorContext } from "./context/CapacitorContext";
import { AppConfigContext } from "./context/AppConfigContext";
import { ConversationsContext } from "./context/ConversationsContext";
import { handleMetaTags } from "./utils/appconfig";

const Main = () => {
  const [socket, setupSocket] = useSocket();
  const [firstLoad, setFirstLoad] = useState(true);
  const [videoShowed, setVideoShowed] = useState(false);

  const appconfig = useContext(AppConfigContext);
  const { setSocket } = useContext(SocketContext);
  const { setConversation } = useContext(MessagesContext);

  const { conversation } = useContext(ConversationsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { setupDevice, setupPlatform } = useContext(CapacitorContext);
  const { user, refresh, userLoggedIn, getCurrentUser, setupInterceptors } =
    useContext(AuthContext);

  const { firebaseConfig, getAppConfig } = appconfig;

  const translations = useTranslations();
  const { handleDefaultLang } = translations;

  const { organization } = useOrganization();

  useEffect(() => {
    setupDevice();
    userLoggedIn();
    setupPlatform();
    setupInterceptors();
    handleMetaTags(appconfig);
    handleURLParams(handleLoginURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!firstLoad) {
      setupSocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad]);

  useEffect(() => {
    setSocket(socket);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    if (conversation && conversation !== null) {
      setConversation(conversation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation]);

  useEffect(() => {
    if (user !== null) {
      if (firstLoad) {
        setFirstLoad(false);
      }

      const route = window.location.pathname;
      if (route.includes("login")) {
        navigate("/");
      }

      if (user.show_video) {
        setTimeout(handleVideo, 1000);
      }

      getAppConfig(user?.staff?.role === "admin");
    }

    handleDefaultLang();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (refresh) handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handleVideo = () => {
    const intro_video_url = getValue(appconfig, "intro_video_url");
    const organizationSelected = organization?.organization_id;
    const organizationHasVideo = organization?.welcome_video;

    if (!videoShowed) {
      if (
        (intro_video_url !== "" && intro_video_url !== null) ||
        (organizationHasVideo && organizationSelected)
      ) {
        setVideoShowed(true);
        modalComponent("Welcome", <IntroVideo />, { size: "lg" });
      }
    }
  };

  const handleRefresh = () => {
    modalComponent("", <LoginForm handleCallback={clearModal} />);
  };

  const handleLoginURL = (uid) => {
    const AuthService = FirebaseService(firebaseConfig);
    AuthService.setToken(uid);
    getCurrentUser();
  };

  const renderFavicon = () => {
    const favicon_url = getValue(appconfig, "favicon_url");
    if (favicon_url !== null) {
      return <Favicon url={favicon_url} />;
    }
  };

  return (
    <div id="main" className="container-fluid px-0 overflow-hidden h-100">
      {renderFavicon()}
      <Router className="h-100 overflow-hidden">
        {/*<Pricing path="/pricing" />*/}
        {/*<Checkout path="/checkout/:product_id" />*/}
        {user !== null && organization !== null ? (
          <>
            <Panel key="panel" path="/*" />
            <Walkthrough key="welcome" path="/welcome" />
          </>
        ) : (
          <>
            {getValue(appconfig, "landing_enabled", "boolean") && (
              <Landing key="landing" path="/" />
            )}
            {getValue(appconfig, "spanish_enabled", "boolean") && (
              <LandingSpanish key="landing" path="/latam" />
            )}
            <Auth
              key="auth"
              path={
                getValue(appconfig, "landing_enabled", "boolean")
                  ? "/auth/*"
                  : "/*"
              }
            />
          </>
        )}
      </Router>
      <Modal />
      <ErrorAlert />
      <SuccessAlert />
    </div>
  );
};

export default Main;
