import { useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";
import React, { useContext } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import useTranslations from "../../../hooks/useTranslations";
import { ModalContext } from "../../../context/ModalContext";
import useAppInputs from "../../../hooks/global/useAppInputs";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import { user_types } from "../../../constants/users";

const formInputs = [
  {
    label: "Name",
    value: "",
    type: "text",
    isValid: true,
  },
  {
    label: "Last Name",
    value: "",
    type: "text",
    isValid: true,
  },
  {
    label: "Email",
    value: "",
    type: "text",
    isValid: true,
  },
  {
    label: "User Type",
    value: 1,
    type: "select",
    options: [
      {
        label: "Admin",
        value: 1,
      },
      {
        label: "Manager",
        value: 2,
      },
      {
        label: "Member",
        value: 3,
      },
    ],
    isValid: true,
  },
];

const OrganizationUserForm = ({ userId }) => {
  const [spinner, setSpinner] = useState(false);
  const translations = useTranslations();

  const smallDevice = useWindowSize(450);
  const colInputs = smallDevice ? 12 : 6;

  const { renderAllInputs, validateInputs, inputs, clearAllInputs } =
    useAppInputs(formInputs, colInputs);
  const {
    organization,
    user_organization,
    addUserToOrganization,
    updateUserOrganization,
    getSingleUserOrganization,
    setPropertyUserOrganization,
    setUserOrganization,
  } = useContext(OrganizationsContext);
  const { alert } = useContext(ModalContext);

  useEffect(() => {
    if (!isNaN(parseInt(userId))) {
      getUserData();
    } else {
      setUserOrganization({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const getUserData = () => {
    const organization_id = organization?.organization_id;
    getSingleUserOrganization(userId, organization_id);
  };

  const handleError = (err) => {
    setSpinner(false);
    alert(err);
  };

  const handleSuccess = () => {
    setSpinner(false);
    setUserOrganization({});
    navigate("/organization/admin-panel");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSpinner(true);
    const { organization_id } = organization;

    if (isNaN(parseInt(userId))) {
      addUserToOrganization(
        {
          ...user_organization,
          ...user_organization.user,
          organization_id,
        },
        handleSuccess
      ).catch(handleError);
    } else {
      updateUserOrganization(
        {
          organization_id,
          user_id: userId,
          user_type_id: user_organization.user_type_id,
        },
        handleSuccess
      ).catch(handleError);
    }

    // createUserNoSignUp(customer);
  };

  const renderUserTypes = () => {
    if (Array.isArray(user_types)) {
      return user_types.map((user_type) => (
        <option
          className="text-capitalize"
          key={user_type.user_type_id}
          value={user_type.user_type_id}
        >
          {user_type.name}
        </option>
      ));
    }
  };

  const handleChangeUserProperty = (property, value) => {
    const newUserData = { ...user_organization?.user };
    newUserData[property] = value;
    setPropertyUserOrganization("user", newUserData);
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-6 ">
            <label className="p-0">{translations.auth?.name}</label>
            <input
              className="form-control"
              type="text"
              value={user_organization?.user?.name}
              onChange={(e) => handleChangeUserProperty("name", e.target.value)}
            />
          </div>
          <div className="col-6 ">
            <label className="p-0">{translations.auth?.last_name}</label>
            <input
              className="form-control"
              type="text"
              value={user_organization?.user?.last_name}
              onChange={(e) =>
                handleChangeUserProperty("last_name", e.target.value)
              }
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-6 ">
            <label className="p-0">{translations.auth?.email}</label>

            <input
              className="form-control"
              type="email"
              value={user_organization?.user?.email}
              onChange={(e) =>
                handleChangeUserProperty("email", e.target.value)
              }
            />
          </div>
          <div className="col-6 ">
            <label className="p-0">{translations.auth?.user_type}</label>
            <select
              value={user_organization?.user_type_id}
              className={`form-control`}
              onChange={(event) =>
                setPropertyUserOrganization(
                  "user_type_id",
                  Number(event.target.value)
                )
              }
            >
              {renderUserTypes()}
            </select>
          </div>
        </div>

        <div className="row ">
          <div className="col-12 text-end">
            <button type="submit" className="btn btn-primary me-2">
              {spinner ? (
                <div className="spinner-border"></div>
              ) : (
                translations.admin.users.saveBtn
              )}
            </button>

            <Link
              to="/organization/admin-panel"
              className="btn btn-link text-secondary"
            >
              {translations.admin.users.cancelBtn}
            </Link>
          </div>
        </div>
      </form>
    );
  };

  const getTitle = () => {
    return isNaN(parseInt(userId))
      ? translations.admin.users.addUser
      : translations.admin.users.editUser;
  }

  return (
    <div className="container-fluid h-100 bg-white py-3 rounded-3">
      <div className="row">
        <div className="col-12">
          <h1 className="text-gradient text-capitalize d-inline-block">
            {getTitle()} - {organization?.name}
          </h1>
        </div>
      </div>

      <div className="row">
        <div className="col-12 px-0">{renderForm()}</div>
      </div>
    </div>
  );
};

export default OrganizationUserForm;
