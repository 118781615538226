import { useContext } from "react"
import { OrganizationsContext } from "../../context/OrganizationsContext"
import { GroupsContext } from "../../context/GroupsContext";
import { AuthContext } from "../../context/AuthContext";


export const useCampaignAccess = () => {
  const { organization } = useContext(OrganizationsContext);
  const { groups } = useContext(GroupsContext);
  const { user_organization } = useContext(AuthContext);

  const getGroupsData = (ids) => {
    const currentGroups = groups.filter(obj => {
      if(ids.includes(obj.group_id)) return obj; 
    });

    return currentGroups;
  }

  const getUsersData = (ids) => {
    const currentUsers = organization.users?.filter(obj => {
      if(ids.includes(obj.user_id)) return obj.user; 
    });

    return currentUsers;
  }

  const getCurrentUserCampaigns = (campaigns) => {
    let campaignsAvailable = [];

    if(user_organization?.user_type.name === 'Member'){
      campaigns.forEach(obj => {
        user_organization.campaigns_access.forEach(access => {
          if(obj.campaign_id === access.campaign_id) campaignsAvailable.push(obj);
        })
      });
    } 

    if(user_organization?.user_type.name === 'Manager'){
      campaigns.forEach(obj => {
        if(obj.user_id === user_organization.user.user_id){
          campaignsAvailable.push(obj);
        } else {
          user_organization.campaigns_access.forEach(access => {
            if(obj.campaign_id === access.campaign_id) {
              campaignsAvailable.push(obj);
            } 
          });
        }
      });
    }

    if(user_organization?.user_type.name === 'Admin') {
      campaignsAvailable = campaigns;
    }

    return campaignsAvailable;
  }

  return {
    getGroupsData,
    getUsersData,
    getCurrentUserCampaigns
  }

}