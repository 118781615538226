import { useContext, useEffect } from "react";
import OrganizationForm from "../../../components/organization/organizations/OrganizationForm";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import OrganizationsHandler from "../../../components/organization/organizations/OrganizationHandler";
import useOrganization from "../../../hooks/useOrganization";

const OrganizationSettings = ({ organization_id }) => {
  const { organization } = useOrganization();
  const {
    setOrganization,
    organizationToEdit,
    getSingleOrganization,
    saveOrganization,
  } = useContext(OrganizationsContext);

  useEffect(() => {
    const editMode = true;
    if (!isNaN(parseInt(organization_id))) {
      getSingleOrganization(organization_id, {}, editMode);
    } else {
      setOrganization(organization, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization_id]);

  const handleCancel = () => {
    window.history.go(-1);
  };

  const { handleDeleteOrganization } = OrganizationsHandler();

  const onSave = () => {
    saveOrganization(organizationToEdit);
    window.history.go(-1);
  };

  const renderEditForm = () => {
    if (organizationToEdit != null) {
      return (
        <div className="position-relative w-100" style={{ height: "calc(100% - 50px)", overflowY: "auto" }}>
          <p className="bold">Edit {organizationToEdit?.name}</p>
          <OrganizationForm
            organizationToEdit={organizationToEdit}
            onDelete={handleDeleteOrganization}
          />
        </div>
      );
    }
  };

  const renderSaveButton = () => {
    if (organizationToEdit != null) {
      return (
        <button
          type="button"
          className={`col-12 btn btn-primary col-md-6`}
          onClick={() => onSave(organizationToEdit)}
        >
          <i className="fas fa-save me-2"></i> Save
        </button>
      );
    }
  };

  return (
    <div
      className="d-flex flex-column h-100 w-100"
      style={{ overflowY: "auto" }}
    >
      <div className="p-3">
        <h1>Organization Settings</h1>
      </div>
      <div className="p-3 position-relative d-flex flex-column align-items-start justify-content-between" style={{ height: "calc(100% - 88px)"}}>
        {renderEditForm()}
        <div className="row p-0">
          <button
            type="button"
            className={`btn  col-12 col-md-6`}
            onClick={handleCancel}
          >
            Cancel
          </button>
          {renderSaveButton()}
        </div>
      </div>
    </div>
  );
};

export default OrganizationSettings;
