import api from "./api";

const route = "/super_fetch";

const SuperFetchService =  {
  getMessages: (assistant_id) => api.get(`${route}/messages/${assistant_id}`),
  getOutput: (data) => api.post(`${route}`, {...data}),
  postSuperFetch: (data) => api.post(`${route}/v2`, {...data}),
};

export default SuperFetchService;