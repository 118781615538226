import { getArgs } from "../utils";
import api from "./api";

const route = "/tutorials";

const TutorialsService = {
  getTutorials: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getSingleTutorial: (tutorial_id) => api.get(`${route}/${tutorial_id}`),
};
export default TutorialsService;
