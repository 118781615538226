import React, { useContext, useState } from "react";
import { SuperFetchContext } from "../../context/SuperFetchContext";
import useSuperFetch from "../../hooks/super_fetch/useSuperFetch";
import { ModalContext } from "../../context/ModalContext";
import SuperFetchSaveAvatar from "./SuperFetchSaveAvatar";
import SuperFetchSaveForm from "./SuperFetchSaveForm";

const SuperFetchGenerateBar = () => {
  const { loading, feedback } = useContext(SuperFetchContext);
  const { modalComponent } = useContext(ModalContext);

  const [outputQty, setOutputQty] = useState(1);

  const { generateFetch } = useSuperFetch();

  const handleSaveAsTemplate = () => {
    modalComponent("Save Super Fetch as Template", <SuperFetchSaveForm />);
  };

  const handleSaveAsAvatar = () => {
    modalComponent("Save Super Fetch as Avatar", <SuperFetchSaveAvatar />);
  };

  const renderButtons = () => {
    if (feedback.formActive) {
      return [
        <div key="template" className="col-12 col-md-6 my-2">
          <button
            type="button"
            className="btn btn-accent w-100"
            onClick={handleSaveAsTemplate}
          >
            <span className="hide-mobile">Save as Template</span>
            <i className="fas fa-shapes ms-2"></i>
          </button>
        </div>,
        <div key="avatar" className="col-12 col-md-6 my-2">
          <button
            type="button"
            className="btn btn-accent w-100"
            onClick={handleSaveAsAvatar}
          >
            <span className="hide-mobile">Save as Avatar</span>
            <i className="fa fa-users ms-2"></i>
          </button>
        </div>,
      ];
    }
  };

  return (
    <div className="row">
      {/* {renderButtons()} */}
      <div className="col-12 col-md-6 mt-2">
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0 me-2">Outputs:</p>
          <div className="d-flex align-items-center">
            <button
              className="btn btn-accent cursor-default me-2"
              style={{ height: "max-content" }}
              disabled={loading}
            >
              {outputQty}
            </button>
            <div className="d-inline-flex flex-column">
              <i
                className="fa fa-chevron-up cursor-pointer mb-2"
                onClick={() => setOutputQty(outputQty + 1)}
              />
              <i
                className="fa fa-chevron-down cursor-pointer"
                onClick={() => setOutputQty(outputQty > 0 ? outputQty - 1 : 0)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 mt-2 position-relative">
        <button
          onClick={() => generateFetch(outputQty)}
          className="btn btn-primary w-100"
          disabled={loading}
        >
          {loading ? <div className="spinner-border" /> : "Generate"}
          <i className={`fa fa-arrow-right ms-2 ${loading ? "d-none" : ""}`} />
        </button>
      </div>
    </div>
  );
};

export default SuperFetchGenerateBar;
