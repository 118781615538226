


const tabs = [
  {
    key: 'content',
    label: 'Content',
  },
  {
    key: 'advanced',
    label: 'Advanced',
  },
  {
    key: 'superfetch',
    label: 'Super Fetch',
  },
];

const SettingsNav = ({ currentTab, setCurrentTab }) => {

  return (
    <ul 
      className="nav nav-tabs settings-nav" 
      style={{
        overflowX: 'auto',
        overflowY: 'hidden',
        flex: '0 0 auto',
        maxHeight: '57px',
        flexWrap: 'nowrap'
      }}
    >
      {
        tabs.map((tab, index) => {
          return(
            <li 
              key={tab.key} 
              className="nav-item px-0"
              style={{
                width: 'max-content',
                height: 'max-content'
              }}
            >
              <button 
                style={{
                  width: 'max-content'
                }}
                className={`nav-link 
                  ${tab.key === currentTab ? 'active text-primary' : 'text-dark'}
                `}
                aria-current="page" 
                onClick={() => {
                  setCurrentTab(tab.key);
                }}
              >
                {tab.label}
              </button>
            </li>
          )
        })
      }
    </ul>
  )
};

export default SettingsNav;


 