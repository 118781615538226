import React, { useRef, useEffect, useState } from "react";

const GeneratorInput = ({ spinner, handleSubmit, placeholder }) => {
  const [prompt, setPrompt] = useState("");

  const textArea = useRef(null);

  useEffect(() => {
    const setupHeight = () => {
      if (prompt === "") {
        textArea.current.style.height = "auto";
      } else {
        textArea.current.style.height = textArea.current.scrollHeight + "px";
      }
    };

    setupHeight();
  }, [textArea]);

  return (
    <div className="card my-3 m-auto p-3 w-100">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(prompt);
        }}
        className="row"
      >
        <div className="col-10 ps-0 ">
          <textarea
            type="text"
            ref={textArea}
            value={prompt}
            className="form-control shadow-sm border"
            placeholder={placeholder}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </div>
        <div className="col-2 px-0">
          <button
            type="submit"
            disabled={spinner}
            className="btn btn-primary px-0 w-100"
          >
            {spinner ? (
              <div className="spinner-border"></div>
            ) : (
              <i className="fa fa-paper-plane"></i>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default GeneratorInput;
