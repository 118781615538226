import React, { useContext, useEffect, useState } from "react";
import useTranslations from "../../../hooks/useTranslations";
import OrganizationCard from "../../../components/organization/organizations/OrganizationCard";
import OrganizationsHandler from "../../../components/organization/organizations/OrganizationHandler";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import { AuthContext } from "../../../context/AuthContext";

const Organizations = ({ isAdminView }) => {
  const [query, setQuery] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const { handleCreateOrganization } = OrganizationsHandler();
  const translations = useTranslations();
  const { getUserOrganizations, getAdminOrganizations, organization } =
    useContext(OrganizationsContext);

  const { user } = useContext(AuthContext);

  const fetchData = () => {
    if (!isAdminView) {
      getUserOrganizations(setOrganizations, query);
    }
    if (isAdminView) {
      getAdminOrganizations(setOrganizations, query);
    }
  }
  
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ query]);

  const renderOrganizations = () => {
    if (organizations != null && organizations.length > 0) {
      return organizations
        .filter((organization) => organization !== null)
        .map((currentOrganization) => (
          <OrganizationCard
            organization={currentOrganization}
            key={currentOrganization.organization_id}
          />
        ));
    }
    return <p>{translations.organization.noOrganizations}</p>;
  };

  const renderCreateButton = () => {
    if (user?.staff && user?.staff !== null) {
      return (
        <button
          className="btn btn-primary col-12"
          onClick={() => handleCreateOrganization(fetchData)}
        >
          + {translations.conversations.button}
        </button>
      );
    }
  };

  return (
    <div
      className="container-fluid bg-white card position-relative h-100"
    >
      <div className="row">
        <div className="col">
          <h1 className="mb-0 text-capitalize text-gradient d-inline-block">
            Organizations
          </h1>
        </div>
      </div>
      <div className="row align-items-center mb-3">
        <div className="col-12 col-md-10 mt-3">
          <input
            type="text"
            value={query}
            className="form-control p-0 py-2"
            disabled={organizations?.length <= 0}
            placeholder={translations?.organization?.search}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="col-md-2 col-12 mt-3" style={{ padding: "0 10px" }}>
          {renderCreateButton()}
        </div>
      </div>
      <div className="row mt-3 position-relative" style={{ overflowY: "auto" }}>
        {renderOrganizations()}
      </div>
    </div>
  );
};

export default Organizations;
