import { saveAs } from "file-saver";
import { useContext } from "react";
import { DocumentsContext } from "../../context/DocumentsContext";


const useFilesConverter = () => {
  const { documentBuffer, getDocumentConverted } = useContext(DocumentsContext);

  const export2Doc = (filename = '', text) => {
    var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    var html = preHtml+document.querySelector(".codex-editor__redactor")?.innerHTML+postHtml;

    let content = html
    
    if(typeof text === "string") content = text;
    let blob = new Blob(['ufeff', content], {
        type: 'application/msword'
    });
    
    var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(content);
    
    filename = filename?filename+'.doc':'document.doc';
    
    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);
      
    if(navigator.msSaveOrOpenBlob ){
        navigator.msSaveOrOpenBlob(blob, filename);
    }else{
        downloadLink.href = url;
        
        downloadLink.download = filename;
        
        downloadLink.click();
    }
    
    document.body.removeChild(downloadLink);
  }

  const export2pdf = async (buffer) => {
    const file = new File([buffer], "archivo.pdf", {
      type: "application/pdf",
    });

    saveAs(file);
  }

  const getDocumentBuffer = (documentId) => {
    getDocumentConverted(documentId);
  }

  return {
    export2Doc,
    export2pdf,
    getDocumentBuffer,
    documentBuffer
  }
}

export default useFilesConverter;