import React, { useContext, useEffect, useRef, useState } from "react";
import { AvatarsContext } from "../../context/AvatarsContext";
import AvatarInputMessage from "./AvatarInputMessage";
import AvatarMessageCard from "./AvatarMessageCard";
import useTranslations from "../../hooks/useTranslations";
import { MessagesContext } from "../../context/MessagesContext";
import LoadingCard from "../global/LoadingCard";

const AvatarOutput = ({
  templatesActive,
  setTemplatesActive,
  disableTemplatesBtn,
}) => {
  const initialMessagesRendered = 10;
  const addedMessagesOnTopScroll = 10;

  const [firstLoad, setFirstLoad] = useState(true);
  const [inputValue, setInputValue] = useState("");

  const [currentMessages, setCurrentMessages] = useState([]);
  const [lastScrollHeight, setLastScrollHeight] = useState(0);
  const [allMessagesShown, setAllMessagesShown] = useState(false);

  const [loadedPrevMessages, setLoadedPrevMessages] = useState(false);
  const [newMessageReceived, setNewMessageReceived] = useState(false);

  const { avatar } = useContext(AvatarsContext);
  const {
    spinner,
    saveMessage,
    runingThread,
    threadMessages,
    getThreadMessages,
    appendThreadMessage,
  } = useContext(MessagesContext);

  const translations = useTranslations();
  const messagesContainer = useRef(null);

  useEffect(() => {
    if (avatar && avatar !== null && !spinner) {
      getThreadMessages(avatar.avatar_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar]);

  useEffect(() => {
    handleMessagesToRender();
    validateNewMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadMessages]);

  const handleMessagesToRender = () => {
    if (Array.isArray(threadMessages)) {
      const totalThreadMessages = threadMessages.length;
      if (totalThreadMessages > 10) {
        const startIndex = totalThreadMessages - initialMessagesRendered;
        const messagesToRender = threadMessages.slice(startIndex);
        setCurrentMessages(messagesToRender);
      } else {
        setCurrentMessages(threadMessages);
      }
    }
  };

  const validateNewMessage = () => {
    const lastThreadMessageId =
      threadMessages[threadMessages.length - 1]?.message_id;
    const lastCurrentMessageId =
      currentMessages[currentMessages.length - 1]?.message_id;

    const validArrays =
      Array.isArray(threadMessages) && Array.isArray(currentMessages);
    const validLength = threadMessages.length > 0 && currentMessages.length > 0;

    if (validArrays && validLength) {
      if (lastCurrentMessageId !== lastThreadMessageId) {
        handleMessagesToRender();
        setNewMessageReceived(true);
      }
    }
  };

  useEffect(() => {
    handleBottomMessagesLoad();
    handlePrevMessagesLoaded();
    handleMessagesShown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMessages]);

  const handleBottomMessagesLoad = () => {
    if (firstLoad || newMessageReceived) handleScrollBottom();
    setNewMessageReceived(false);
  };

  const handlePrevMessagesLoaded = () => {
    if (loadedPrevMessages) setContainerPosition(lastScrollHeight);

    const container = messagesContainer?.current;
    setLastScrollHeight(container?.scrollHeight);
    setLoadedPrevMessages(false);
  };

  const handleMessagesShown = () => {
    if (currentMessages.length === threadMessages.length) {
      setAllMessagesShown(true);
    } else {
      setAllMessagesShown(false);
    }
  };

  const setContainerPosition = (scrollHeight) => {
    const container = messagesContainer?.current;
    container.scrollTop = container.scrollHeight - scrollHeight;
  };

  const handleScrollBottom = () => {
    if (messagesContainer.current) {
      messagesContainer.current.scrollTo({
        top: messagesContainer.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleContainerScroll = (event) => {
    handleOnScrollTop(event);
    handleOnScrollBottom(event);
  };

  const handleOnScrollTop = () => {
    const totalThreadMessages = threadMessages.length;
    const totalCurrentMessages = currentMessages.length;

    const container = messagesContainer?.current;
    const scrollTop = container?.scrollTop;

    //Only load previous messages in case the user reaches the top of the container and
    //threadMessages is a valid Array
    if (scrollTop === 0 && Array.isArray(threadMessages)) {
      setFirstLoad(false);

      //Also only will loaded new messages if totalThreadMessages is greater than
      //the totalCurrentMessages already loaded, if not, it wont load any message
      //because it means that all thread messages has been loaded.
      if (totalThreadMessages > totalCurrentMessages) {
        const newTotalCurrentMessages =
          totalCurrentMessages + addedMessagesOnTopScroll;
        setLoadedPrevMessages(true);

        if (totalThreadMessages >= newTotalCurrentMessages) {
          const startIndex = totalThreadMessages - newTotalCurrentMessages;
          const newMessagesToRender = threadMessages.slice(startIndex);

          setCurrentMessages(newMessagesToRender);
        } else {
          setCurrentMessages(threadMessages);
        }
      }
    }
  };

  const handleOnScrollBottom = () => {
    const container = messagesContainer?.current;

    if (container) {
      const scrollHeight = container.scrollHeight;
      const currentPosition = container.scrollTop + container.clientHeight;
      const isInBottom = currentPosition >= scrollHeight;

      if (isInBottom) {
        handleMessagesToRender();
      }
    }
  };

  const handleSubmitMessage = async (event) => {
    event.preventDefault();
    if (String(inputValue).length > 0) {
      const messageData = {
        content: inputValue,
        avatar_id: avatar.avatar_id,
      };
      setInputValue("");
      saveMessage(messageData, appendThreadMessage);
    }
  };

  const renderSpinner = () => {
    if (runingThread) {
      let text = translations.conversations.spinner;
      text = text.replace("Fetch", avatar?.name);
      return <LoadingCard text={text} />;
    }
  };

  const renderMessages = () => {
    if (threadMessages?.length > 0) {
      return currentMessages.map((message, index) => (
        <AvatarMessageCard
          message={message}
          key={message.message_id}
          lastMessage={index === threadMessages.length - 1}
        />
      ));
    }
  };

  const renderAllMessagesShown = () => {
    if (allMessagesShown) {
      return (
        <div
          className="col-12 d-flex mt-1 justify-content-center"
          style={{ height: "max-content" }}
        >
          <span className="border bg-accent badge badge-pill mb-3 mx-auto ">
            {translations.conversation.max_messages}
          </span>
        </div>
      );
    }
  };

  return (
    <div
      className="card bg-light h-100 position-relative"
      style={{ overflowY: "hidden" }}
    >
      <div className="card-header">
        <h3>{avatar?.name} - Avatar</h3>
      </div>

      <div
        className="card-body position-relative pe-0 pt-0 w-100"
        style={{ height: "calc(100% - 50px)" }}
      >
        <div
          className="row pe-3 pt-2 flex-column justify-content-start flex-nowrap"
          style={{
            height: "calc(100% - 115px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
          ref={messagesContainer}
          onScroll={handleContainerScroll}
        >
          {renderAllMessagesShown()}
          {renderMessages()}
          {renderSpinner()}
        </div>
        <AvatarInputMessage
          value={inputValue}
          spinner={runingThread}
          setValue={setInputValue}
          templatesActive={templatesActive}
          handleSubmit={handleSubmitMessage}
          setTemplatesActive={setTemplatesActive}
          disableTemplatesBtn={disableTemplatesBtn}
        />
      </div>
    </div>
  );
};

export default AvatarOutput;
