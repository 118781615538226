import { getArgs } from "../utils";
import api from "./api";

const route = "/products";

const ProductService = {
  getAvailableProducts: () => api.get(`${route}/available`),
  getAllProducts: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getSingleProduct: (product_id) => api.get(`${route}/${product_id}`),
  postProduct: (product) => api.post(`${route}`, product),
  putProduct: (product) => api.put(`${route}`, product),
};

export default ProductService;