import React, { useContext, useState } from "react";
import useTranslations from "../../hooks/useTranslations";
import { AvatarsContext } from "../../context/AvatarsContext";
import { ModalContext } from "../../context/ModalContext";

export default function EditAvatarFile({ file }) {
  const [editingFile, setEditingFile] = useState(file);
  const translations = useTranslations();

  const { updateAvatarFile, spinner } = useContext(AvatarsContext);
  const { clearModal } = useContext(ModalContext);

  const handleUpdateFile = async () => {
    await updateAvatarFile(editingFile);
    clearModal();
  };

  const renderIcon = () => {
    if (spinner) {
      return (
        <div
          className="spinner-border position-absolute top-0 bottom-0 
      end-0 m-auto me-3"
        ></div>
      );
    }
    return (
      <i
        className="position-absolute top-0 bottom-0 
          end-0 fas fa-bookmark fs-4 m-auto me-3"
        style={{
          height: "max-content",
        }}
      />
    );
  };

  return (
    <>
      <label className="mb-0 px-0">
        {translations.avatars.editable_file_name}
      </label>
      <input
        className="form-control sm"
        value={editingFile.file_data.name}
        type="text"
        style={{
          height: "30px",
        }}
        onChange={(e) => {
          const value = e.target.value;
          setEditingFile({
            ...editingFile,
            file_data: {
              ...editingFile.file_data,
              name: value,
            },
          });
        }}
      />

      <label className="mb-0 px-0">{translations.avatars.content}</label>
      <textarea
        rows="5"
        type="text"
        value={editingFile.content}
        className="form-control"
        style={{
          maxHeight: "calc(100% - 80px)",
        }}
        onChange={(e) => {
          const value = e.target.value;
          setEditingFile({
            ...editingFile,
            content: value,
          });
        }}
      />

      <div className="row mt-3">
        <div className="col-6">
        <button
            className="btn btn-link w-100 text-muted"
            onClick={() => clearModal()}
          >
            {translations.avatars.cancel}
          </button>
        </div>
        <div className="col-6">
          <button
            className="btn btn-primary w-100 position-relative"
            disabled={spinner}
            onClick={handleUpdateFile}
          >
            {translations.avatars.save}
            {renderIcon()}
          </button>
        </div>
      </div>
    </>
  );
}
