const conversation = {
  header: {
    edit: "Edit Conversation",
  },
  separate_commas: "Separate with commas",
  max_messages: "No more messages",
  continue_generating: "Continue generating",
  form: {
    name: "Name",
  },
  message: {
    share: "Share Message",
    edit: "Edit Message",
    save: "Save Message",
    copy: "Copy to clipboard",
    pending: "Working on this...",
    append_avatar: "Add to Avatar",
  },
  input: {
    send: "Send",
    enhance: "Enhance",
    placeholder: "Write an instruction",
    templates: {
      button: "Templates",
      title: "Templates",
      cancel: "Cancel",
    },
  },
};
export default conversation;
