import React, { useContext, useEffect, useState } from "react";
import useWindowSize from "../hooks/useWindowSize";
import SingleConversation from "./SingleConversation";
import useTranslations from "../hooks/useTranslations";
import { updatePromptValues } from "../utils/templates";
import MobileModal from "../components/mobile/MobileModal";
import { AvatarsContext } from "../context/AvatarsContext";
import { MessagesContext } from "../context/MessagesContext";
import { TemplatesContext } from "../context/TemplatesContext";
import TemplateList from "../components/templates/TemplatesList";
import { ConversationsContext } from "../context/ConversationsContext";

const TemplateApply = ({ idTemplate, conversation_id }) => {
  const [browseTemplates, setBrowseTemplates] = useState(false);
  const [componentRendered, setComponentRendered] = useState("form");

  const translations = useTranslations();
  const smallDevice = useWindowSize(1200);

  const { getSingleConversation } = useContext(ConversationsContext);
  const { conversationAvatar } = useContext(AvatarsContext);
  const {
    spinner,
    generating,
    saveMessage,
    clearMessages,
    setConversation,
  } = useContext(MessagesContext);

  const {
    template,
    templates,
    setTemplate,
    getSingleTemplate,
    getPublicTemplates,
    setPropertyTemplate,
  } = useContext(TemplatesContext);

  useEffect(() => {
    getViewData();
    return () => {
      clearMessages();
      setTemplate(null);
      setConversation(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeField = (newFieldValue, fieldIndex) => {
    const newFields = [...template.fields];
    newFields[fieldIndex] = newFieldValue;
    setPropertyTemplate("fields", newFields);
  };

  const getViewData = () => {
    getSingleTemplate(idTemplate);
    getSingleConversation(conversation_id);
  };

  const handleChangeOption = (fieldIndex, option) => {
    const newField = {
      ...template.fields[fieldIndex],
    };
    newField.value = option;
    changeField(newField, fieldIndex);
  };

  const handleChangeInput = (event, field, fieldIndex) => {
    const value = event.target.value;
    const isValidField = String(value).length > 0;
    const updatedField = {
      ...field,
      value,
      isValidField,
    };
    changeField(updatedField, fieldIndex);
  };

  const fetchTemplates = (params) => {
    getPublicTemplates(params);
  };

  const handleGenerate = () => {
    const validFields = validateFields();

    let content = template?.content;

    template?.fields?.forEach((field) => {
      content = updatePromptValues(content, field.name, field.value);
    });

    if (validFields) {
      const messageData = {
        content,
        stream: false,
        conversation_id,
        deleteUserMsg: true,
        saveOnConversation: false,
        avatar_id: conversationAvatar?.avatar_id || null,
      };
      saveMessage(messageData);
      setComponentRendered("chat");
    }
  };

  const validateFields = () => {
    let validFields = true;
    const updatedFields = [];

    template.fields?.forEach((field) => {
      const newField = { ...field };

      if (field.value.length <= 0) {
        validFields = false;
        newField.isValidField = false;
      }

      updatedFields.push(newField);
    });

    if (!validFields) {
      setPropertyTemplate("fields", updatedFields);
    }

    return validFields;
  };

  const handleBackToForm = () => {
    setComponentRendered("form");
  };

  const renderInput = (field, fieldIndex) => {
    const fieldName = field.name.replace(/^\w/, (c) => c.toUpperCase());

    return (
      <div key={fieldIndex} className={`w-100 mb-2`}>
        <label>
          <b>{fieldName}</b>
        </label>
        <label className="small text-muted d-block mb-1">{field.label}</label>
        <input
          type={field.type !== "number" ? "text" : field.type}
          className={`form-control z-2 w-${
            field.type === "number" ? "25 text-center" : "100"
          } ${field.isValidField ? "" : "border border-danger"}`}
          value={field.value}
          onChange={(event) => {
            handleChangeInput(event, field, fieldIndex);
          }}
        />
        <span
          className={`text-danger 
          ${field.isValidField ? "d-none" : ""}`}
        >
          The input can't be empty
        </span>
      </div>
    );
  };

  const renderSelect = (field, fieldIndex) => {
    const fieldName = field.name.replace(/^\w/, (c) => c.toUpperCase());
    return (
      <div key={fieldIndex} className="dropdown mb-2 d-flex flex-column">
        <label>
          <b>{fieldName}</b>
        </label>
        <label className="small text-muted d-block mb-1">{field.label}</label>
        <button
          className="form-control dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {field.value}
        </button>

        <ul
          className="dropdown-menu w-100"
          style={{
            padding: 0,
          }}
        >
          {field?.options?.map((opt, optIndex) => (
            <button
              key={optIndex}
              onClick={() => {
                handleChangeOption(fieldIndex, opt);
              }}
              type="button"
              className="btn w-100 border form-control"
              style={{
                borderRadius: 0,
              }}
            >
              {opt}
            </button>
          ))}
        </ul>
      </div>
    );
  };

  const renderTemplateFields = () => {
    if (!Array.isArray(template?.fields)) {
      return;
    }

    const currentFields = template?.fields?.sort((a, b) => {
      return a.order - b.order;
    });

    return currentFields?.map((field, fieldIndex) => {
      if (field.type === "select" && Array.isArray(field.options)) {
        return renderSelect(field, fieldIndex);
      }

      return renderInput(field, fieldIndex);
    });
  };

  const renderTemplateForm = () => {
    let componentActive = true;
    if (componentRendered !== "form") componentActive = false;
    if (!smallDevice) componentActive = true;
    if (browseTemplates && !smallDevice) componentActive = false;

    return (
      <div
        className={`${
          componentActive ? "" : "d-none"
        } col-12 px-0 col-xl-5 h-100 
        position-relative d-flex flex-column`}
      >
        <h3>{template?.name}</h3>
        <div className="card bg-light mb-3">
          <div className="card-body">
            <label>
              <b>Content</b>
            </label>
            <label className="small text-muted d-block mb-1">
              {template?.description}
            </label>
          </div>
        </div>
        <form
          className="px-1 position-relative"
          style={{
            flex: 1,
            overflowY: "auto",
            marginBottom: "70px",
          }}
        >
          {renderTemplateFields()}
          <button
            className="btn btn-primary position-absolute ms-2 start-0 mb-3 small"
            type="button"
            disabled={spinner || generating}
            onClick={handleGenerate}
          >
            Generate
            <i className="fa fa-arrow-right ms-2"></i>
          </button>
        </form>
        <button
          type="button"
          className={`btn btn-accent position-absolute bottom-0 end-0 mb-3
           ${!smallDevice ? "d-none" : ""} small`}
          style={{ height: "max-content" }}
          onClick={() => setComponentRendered("chat")}
        >
          Conversation
          <i className="fa fa-chevron-right ms-2" />
        </button>
      </div>
    );
  };

  const renderConversation = () => {
    let componentActive = true;
    if (componentRendered !== "chat") componentActive = false;
    if (!smallDevice) componentActive = true;

    return (
      <div
        className={`${componentActive ? "" : "d-none"}  col-12 
         col-xl-6 position-realtive px-0 end-0 top-0 h-100`}
      >
        <SingleConversation
          conversation_id={conversation_id}
          disableTemplatesBtn
          addGenerateBtn={smallDevice}
          handleGenerateBtn={handleBackToForm}
        />
      </div>
    );
  };

  const handleApply = (templateId) => {
    getSingleTemplate(templateId);
    setBrowseTemplates(false);
  };

  const renderBrowseTemplates = () => {
    let componentActive = true;
    if (!browseTemplates) componentActive = false;

    if (componentActive && !smallDevice) {
      return (
        <div className="col-12 px-0 col-xl-5 h-100 position-relative d-flex flex-column">
          <TemplateList
            title={translations.templates.title}
            templates={templates}
            handleApply={handleApply}
            fetchTemplates={fetchTemplates}
            disableAddBtn
            filtersActive
          />
        </div>
      );
    }

    if (componentActive && smallDevice) {
      return (
        <MobileModal
          title={translations.templates.title}
          isOpen={browseTemplates}
          setIsOpen={setBrowseTemplates}
        >
          <div
            className={`col-12 col-xl-5 h-100 
            position-relative d-flex flex-column p-2 pb-4`}
          >
            <TemplateList
              templates={templates}
              handleApply={handleApply}
              fetchTemplates={fetchTemplates}
              disableAddBtn
              filtersActive
            />
          </div>
        </MobileModal>
      );
    }
  };

  return (
    <div
      className="container-fluid position-relative card w-100 p-2 overflow-hidden
      d-flex flex-column justify-content-center align-items-center h-100 overflow-x-hidden"
    >
      <div className="row px-2 px-0 py-3">
        <div className="col-12 col-lg-6">
          <h2 className="mb-0 text-capitalize text-gradient d-inline-block">
            {translations.templates.apply_template}
          </h2>
        </div>

        <div className="col-12 mt-2 col-lg-6 d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-outline-primary small h-max-content"
            onClick={() => setBrowseTemplates(!browseTemplates)}
          >
            <span className="hide-mobile small">Browse</span> Templates
            <i className="fa fa-shapes ms-2" />
          </button>
        </div>
      </div>
      <div className="w-100 row justify-content-between px-md-4 position-relative px-2 template-apply-container">
        {renderTemplateForm()}
        {renderBrowseTemplates()}
        {renderConversation()}
      </div>
    </div>
  );
};

export default TemplateApply;
