export const user_types = [
  {
    user_type_id: 1,
    name: "Admin",
  },
  {
    user_type_id: 2,
    name: "Manager",
  },
  {
    user_type_id: 3,
    name: "Member",
  },
]