import React, { useContext, useEffect } from "react";
import { ConversationsContext } from "../../context/ConversationsContext";
import { ModalContext } from "../../context/ModalContext";
import ConversationForm from "./ConversationForm";
import useTranslations from "../../hooks/useTranslations";
import { navigate } from "@reach/router";
import { setupTooltips } from "../../utils";

const ConversationsActions = ({
  isTagsPath,
  filter,
  sortBy,
  setSortBy,
  setFilter,
  viewArchived,
  setViewArchived,
}) => {
  const translations = useTranslations();
  const { modalComponent, clearModal } = useContext(ModalContext);
  const { createConversation } = useContext(ConversationsContext);

  useEffect(() => {
    setupTooltips();
  }, []);

  const setFilterAndNavigate = (value, path) => {
    setFilter(value);
    navigate(path);
  };

  const handleCreateConversation = () => {
    createConversation();
    modalComponent(
      translations.conversations.add,
      <ConversationForm handleCancel={clearModal} />
    );
  };

  const renderButtons = () => {
    if (isTagsPath) {
      const handleGoBack = () => {
        navigate("/");
        setFilter("");
      };

      return (
        <button onClick={handleGoBack} className="btn btn-outline-primary me-2">
          <i className="fa fa-chevron-left me-2"></i> Back to All
        </button>
      );
    }
    return (
      <div className="btn-group w-100 me-2 border br-25" role="group">
        <button
          type="button"
          onClick={() => setFilterAndNavigate("", "/")}
          className={`btn btn-sm ${filter === "" ? "btn-primary" : ""}`}
        >
          {translations.templates.all}
        </button>
        <button
          type="button"
          onClick={() => setFilter("tags")}
          className={`btn btn-sm ${filter === "tags" ? "btn-primary" : ""}`}
        >
          {/* {translations.templates.tag} */}
          Tags
        </button>
      </div>
    );
  };

  return (
    <div className="row">
      <div
        className="col-6 col-md-12 px-0 pe-sm-2 mb-2
        col-xl-5 mb-sm-0 mb-md-2 mb-xl-0"
      >
        <select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          className="form-control d-inline-block"
        >
          <option value="createdAt_desc">{translations.templates.sort.last_created}</option>
          <option value="updatedAt_desc">{translations.templates.sort.last_used}</option>
          <option value="name_asc">A-Z</option>
          <option value="name_desc">Z-A</option>
        </select>
      </div>
      <div className="col-6 col-md-12 col-xl-7 pe-0">
        <div className="row">
          <div className="col-12 col-md-7 pe-2">{renderButtons()}</div>
          <div className="col-12 col-md-5 pe-2">
            <button
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Archived"
              className={`btn hide-mobile me-2 ${
                viewArchived ? "btn-primary" : "btn-outline-primary"
              }`}
              style={{ minWidth: "40px", height: "40px", padding: "5px 1px" }}
              onClick={() => setViewArchived(!viewArchived)}
            >
              <i className="fa fa-archive"></i>
            </button>
            <button
              className="btn hide-mobile btn-outline-primary btn-sm"
              style={{ minWidth: "40px", height: "40px", padding: "5px 1px" }}
              onClick={handleCreateConversation}
            >
              <i className="fa fa-plus" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationsActions;
