import React from "react";
import { Link } from "@reach/router";
import useOrganization from "../../hooks/useOrganization";

const OrganizationPicker = () => {
  const {
    organization,
    organizations,
    selectOrganization,
    setDefaultOrganization,
  } = useOrganization();

  const renderOrganizationsList = () => {
    if (Array.isArray(organizations)) {
      return organizations.map((organization) => (
        <li
          className="my-1"
          key={organization?.organization_id}
          onClick={() => selectOrganization(organization?.organization_id)}
        >
          <div className="dropdown-item hover-success text-dark">
            <div className="d-flex">
              <div className="col-2 me-2">
                <i className="fas fa-globe"></i>
              </div>
              <div className="col-10">{organization?.name}</div>
            </div>
          </div>
        </li>
      ));
    }
  };

  return (
    <div>
      <button
        id="navbarDropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        className="btn small my-2 w-100 text-left text-dark ps-3 pe-0"
      >
        <div className="row">
          <div className="col-2">
            <i className="fas fa-globe"></i>
          </div>
          <div className="col-10 d-flex justify-content-between">
            {organization?.name || "Organization"}
            <span className="dropdown-toggle"></span>
          </div>
        </div>
      </button>
      <ul
        className="dropdown-menu bg-white border"
        aria-labelledby="navbarDropdown"
      >
        {renderOrganizationsList()}
        <li id="personal" key="Personal" className="my-1" onClick={setDefaultOrganization}>
          <div className="dropdown-item hover-success text-dark">
            <div className="d-flex">
              <div className="col-2 me-2">
                <i className="fas fa-user"></i>
              </div>
              <div className="col-10">Personal</div>
            </div>
          </div>
        </li>
        <li className="my-1">
          <Link
            to="/organizations/"
            className="dropdown-item hover-success text-dark"
          >
            <div className="d-flex">
              <div className="col-2 me-2">
                <i className="fas fa-plus"></i>
              </div>
              <div className="col-10">All</div>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default OrganizationPicker;
