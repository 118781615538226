import React from "react";
import useSFInputs from "../../hooks/super_fetch/useSFInputs";
import SuperFetchGenerateBar from "./SuperFetchGenerateBar";

const SuperFetchAside = () => {
  const { renderSFInputs } = useSFInputs();

  return (
    <div className="row">
      {renderSFInputs()}
      <SuperFetchGenerateBar />
    </div>
  );
};

export default SuperFetchAside;
