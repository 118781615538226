import React, { useContext, useEffect, useState } from "react";
import { AvatarsContext } from "../../context/AvatarsContext";
import useTranslations from "../../hooks/useTranslations";
import TextareaInput from "../common/TextareaInput";
import FormActions from "../global/FormActions";

const AvatarInputContext = ({
  setIsEditing,
  handleCancel,
  handleSave,
  isEditing,
  loading,
}) => {
  const [textInputValid, setTextInputValid] = useState(true);
  const [inputValue, setInputValue] = useState("");

  const { avatar } = useContext(AvatarsContext);

  const translations = useTranslations();

  useEffect(() => {
    setInputValue(avatar?.context);
  }, [avatar]);

  const handleChangeContext = (value) => {
    setTextInputValid(value.length >= 1);
    setInputValue(value);
  };

  const handleSaveContext = () => {
    const validInput = String(inputValue).length > 0;
    if (!validInput) return setTextInputValid(false);
    handleSave(inputValue);
  };

  const renderContext = () => {
    if (isEditing) {
      return (
        <div className="col-12 px-0 h-100">
          <TextareaInput
            description={translations.avatar?.context_description}
            label={translations.avatar?.context_header}
            handleChange={handleChangeContext}
            smallDescription={false}
            valid={textInputValid}
            value={inputValue}
          />
          <FormActions
            loading={loading}
            handleCancel={handleCancel}
            handleSubmit={handleSaveContext}
          />
        </div>
      );
    }
    return (
      <div className="card bg-light px-0 h-100 disable-scroll-y">
        <div className="card-header px-3 d-flex justify-content-between align-items-center">
          <h3 className="mb-0">{translations.avatar?.context_header}</h3>
          <button
            className="btn  btn-accent small"
            onClick={() => setIsEditing(true)}
          >
            <span className="hide-mobile small me-2">
              {translations.general.edit}
            </span>
            <i className="small fas fa-edit" />
          </button>
        </div>

        <div className="card-body px-3 active-scroll-y">
          <p>{avatar?.context}</p>
        </div>
      </div>
    );
  };

  return <div className="row avatar-input-context my-3">{renderContext()}</div>;
};

export default AvatarInputContext;
