import React, { useContext, useEffect } from "react";
import SuperFetchOutputContainer from "../components/super_fetch/SuperFetchOutputContainer";
import SuperFetchFeedbackForm from "../components/super_fetch/SuperFetchFeedbackForm";
import SuperFetchGenerateBar from "../components/super_fetch/SuperFetchGenerateBar";
import SuperFetchActions from "../components/super_fetch/SuperFetchActions";
import SuperFetchForm from "../components/super_fetch/SuperFetchForm";
import { SuperFetchContext } from "../context/SuperFetchContext";
import { AvatarsContext } from "../context/AvatarsContext";
import useTranslations from "../hooks/useTranslations";
import useWindowSize from "../hooks/useWindowSize";

const SuperFetch = () => {
  const translations = useTranslations();

  const { smallDevice } = useWindowSize(1200);

  const { getSuperFetchAvatar } = useContext(AvatarsContext);

  const { feedback, toggleFormActive, resetFeedbackInput } =
    useContext(SuperFetchContext);

  useEffect(() => {
    // handleSuperFetchAvatar();
    getSuperFetchAvatar();
    return () => {
      window.removeEventListener("resize", () => {});
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backToEdit = () => {
    resetFeedbackInput();
    toggleFormActive(false);
  };

  const { formActive } = feedback;

  let containerHeight = formActive ? "max-content" : "100%";

  if (smallDevice && formActive) {
    containerHeight = "50%";
  }

  return (
    <div className="container-fluid d-flex bg-white rounded-3 flex-column h-100">
      <div className="row mb-3">
        <div className={`col-12 pb-0 ${smallDevice ? "px-0" : ""}`}>
          <h1 className="text-capitalize text-gradient mb-0 d-inline-block">
            Super Fetch
          </h1>
        </div>
      </div>
      <div className="row" style={{ flex: 1, overflow: "hidden" }}>
        <div
          className={`col-12 col-xl-6 position-relative ${
            smallDevice && formActive ? "pb-0 pt-2 " : ""
          }`}
          style={{
            height: containerHeight,
          }}
        >
          <div className={`row ${!formActive ? "d-none" : ""}`}>
            <div className="col px-0">
              <button
                className={`btn btn-accent me-3 mb-3 `}
                onClick={backToEdit}
              >
                <i className="fa fa-arrow-left me-2"></i>
                Back to Edit
                {translations.superfetch.backToEdit}
              </button>
            </div>
          </div>
          <SuperFetchActions />
          <div
            className={`row super-fetch__form ${!formActive ? "mb-3" : ""}`}
            style={{
              overflowY: "auto",
              marginBottom: formActive ? "120px" : "",
            }}
          >
            <div className="col-12 px-0">
              {formActive ? <SuperFetchFeedbackForm /> : <SuperFetchForm />}
            </div>
          </div>

          <div
            className={`row align-items-center super-fetch__generate-container
          ${smallDevice ? "px-0" : ""}`}
          >
            <SuperFetchGenerateBar />
          </div>
        </div>
        <SuperFetchOutputContainer />
      </div>
    </div>
  );
};

export default SuperFetch;
