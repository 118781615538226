const auth = {
  name: "Nombre",
  show: "Mostrar",
  hide: "Ocultar",
  company: "Compañía",
  last_name: "Apellidos",
  email: "Correo electrónico",
  user_type: "Tipo de Usuario",
  phone: "Teléfono Móvil",
  password: "Contraseña",
  login: "Iniciar Sesión",
  saved: "Usuario guardado",
  signup: "Crear una cuenta",
  deleted: "Usuario eliminado",
  forgot: "¿Olvidaste tu contraseña?",
  has_account: "¿Ya tienes una cuenta?",
  no_account: "¿Aún no tienes una cuenta?",
  no_password:
    "Te hemos enviado un correo electrónico para reestablecer tu contraseña",
  confirm_password: "Confirmar contraseña",
  signup_reason: "¿Cómo te enteraste de nosotros?",
  signup_reasons: [
    "Recomendación",
    "Anuncio en Facebook",
    "Anuncio en Instagram",
    "Otro",
  ],
  other_reason: "Cuéntanos",
  error: "Hubo un error con tu solicitud. Intenta de nuevo más tarde.",
  reset_password:
    "Te hemos enviado un correo electrónico para reestablecer tu contraseña",
  "error_404": "Lo sentimos, no encontramos una cuenta con ese correo electrónico. ¿Te habrás registrado con otro correo?",
};

export default auth;
