import React, { createContext, useContext, useReducer } from "react";
import {
  SET_INPUTS,
  SET_LOADING,
  SET_OUTPUTS,
  SET_FEEDBACK,
  SET_SMALL_DEVICE,
  SET_FEEDBACK_FORM,
  SET_ENHANCED_PROMPT,
  SET_FEEDBACK_CHANGES,
  SET_MESSAGES_HISTORY,
} from "../types";
import { ModalContext } from "./ModalContext";
import SuperFetchReducer from "../reducers/SuperFetchReducer";
import SuperFetchService from "../services/SuperFetchService";
import { feedbackForm, formInputs } from "../utils/superfetch";
import { OrganizationsContext } from "../context/OrganizationsContext";

const initialState = {
  outputs: [],
  superFetch: {},
  loading: false,
  inputs: formInputs,
  smallDevice: false,
  enhancedPrompt: "",
  feedback: feedbackForm,
  messages_history: null,
};

export const SuperFetchContext = createContext(initialState);

export const SuperFetchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SuperFetchReducer, initialState);
  const { organization } = useContext(OrganizationsContext);

  const { alert } = useContext(ModalContext);

  const setOutputs = (payload) => {
    dispatch({ type: SET_OUTPUTS, payload });
  };

  const setLoading = (payload) => {
    dispatch({ type: SET_LOADING, payload });
  };

  const setFeedbackForm = (payload) => {
    dispatch({ type: SET_FEEDBACK_FORM, payload });
  };

  const setEnhancedPrompt = (payload) => {
    dispatch({ type: SET_ENHANCED_PROMPT, payload });
  };

  const changeInputValue = (newValue, inputIndex) => {
    const isValid = newValue.length > 0 ? true : false;
    const updatedInputs = [...state.inputs];
    const newInput = {
      ...state.inputs[inputIndex],
    };

    if (newInput.required) newInput.isValid = isValid;
    newInput.value = newValue;
    updatedInputs[inputIndex] = newInput;

    dispatch({ type: SET_INPUTS, payload: updatedInputs });
  };

  const getMessagesHistory = (assistant_id) => {
    SuperFetchService.getMessages(assistant_id).then((res) => {
      dispatch({ type: SET_MESSAGES_HISTORY, payload: res.data });
    });
  };

  const clearMessagesHistory = () => {
    dispatch({ type: SET_MESSAGES_HISTORY, payload: null });
  }

  const clearInputs = () => {
    const cleanedInputs = state.inputs.map((input) => ({
      ...input,
      value: "",
    }));
    dispatch({ type: SET_INPUTS, payload: cleanedInputs });
  };

  const getSuperFetchPrompt = (outputsQty, assistant_id, prompt) => {
    const service = SuperFetchService.getOutput;
    const allInputsValid = validateInputsLength();

    if (allInputsValid) {
      setOutputs([]);
      setLoading(true);

      let messages = [
        `Use the next instructions to make a response. ${prompt}`,
      ];

      if (outputsQty > 1) {
        messages = [];
        for (let index = 1; index <= outputsQty; index++) {
          messages.push(prompt);
        }
      }

      const data = {
        messages,
        assistant_id,
      };

      if (organization && organization.organization_id) {
        data.organization_id = organization.organization_id;
      }

      service(data).catch((err) => {
        setLoading(false);
        alert(err);
      });
    }
  };

  const getModifiedSuperFetchPrompt = (outputsQty, assistant_id, prompt) => {
    const service = SuperFetchService.getOutput;
    const feedbackChanges = state.feedback.input.value;

    setLoading(true);
    setOutputs([]);

    const modifiedPromptStructure = `
        Use the next instructions to make a response.
        ${prompt}

        And at last consider this requested changes from the user:
        ${feedbackChanges}
      `;

    let messages = [modifiedPromptStructure];

    if (outputsQty > 1) {
      messages = [];
      for (let index = 1; index <= outputsQty; index++) {
        messages.push(modifiedPromptStructure);
      }
    }

    const data = {
      messages,
      assistant_id,
    };

    if (organization && organization.organization_id) {
      data.organization_id = organization.organization_id;
    }

    service(data).catch((err) => {
      setLoading(false);
      console.log(err);
    });
  };

  const validateFeedbackInput = () => {
    const inputValue = state.feedback.input.value;

    if (inputValue.length > 0) {
      return true;
    } else {
      const updatedFeedback = {
        ...state.feedback,
        input: {
          ...state.feedback.input,
          isValid: false,
        },
      };

      dispatch({ type: SET_FEEDBACK, payload: updatedFeedback });
      return false;
    }
  };

  const validateInputsLength = () => {
    let newInputs = [];
    let allInputsValid = true;

    state.inputs.forEach((input) => {
      const updatedInput = { ...input };

      let validInput = true;

      if (input.value.length <= 0 && input.required === true)
        validInput = false;
      updatedInput.isValid = validInput;

      if (!updatedInput.isValid) allInputsValid = false;

      newInputs.push(updatedInput);
    });

    dispatch({ type: SET_INPUTS, payload: newInputs });
    return allInputsValid;
  };

  const changeFeedbackInput = (changes) => {
    const updatedInput = {
      value: changes,
      isValid: true,
    };

    dispatch({ type: SET_FEEDBACK_CHANGES, payload: updatedInput });
  };

  const changeFeedbackLiked = (liked) => {
    const updatedFeedback = {
      ...state.feedback,
      liked,
    };

    dispatch({ type: SET_FEEDBACK, payload: updatedFeedback });
  };

  const changeInputEditable = (editable, inputIndex) => {
    const updatedInputs = [...state.inputs];
    updatedInputs[inputIndex].editable = editable;

    dispatch({ type: SET_INPUTS, payload: updatedInputs });
  };

  const getTemplatePrompt = () => {
    const {
      task,
      topic,
      target,
      person,
      tone,
      format,
      examples,
      callToAction,
      prompt,
      feedback,
    } = getSuperFetchFieldValues();

    let templatePrompt = `
        Task: ${task}

        Consider the next parameters:
          -Topic: ${topic}
          -Target Audience: ${target}
          -Person or professional who will answer the task i request you: ${person}
          -Tone: ${tone}
          -Response Format: I need the response looks like ${format}
          -Examples: Give me answers as similar as possible to these examples: ${examples}
          -Call to Action: ${callToAction}
          -Extra Instructions: ${prompt}
      `;

    if (String(feedback).length > 0) {
      templatePrompt = `
          ${templatePrompt}

          And at last consider this requested changes from the user:
          ${feedback}
        `;
    }

    return templatePrompt
      .trim()
      .replace(/^\s+/gm, "")
      .replace(/^(?=\s*-)/gm, "  ");
  };

  const getSuperFetchFieldValues = () => {
    const inputs = [...state.inputs];

    const taskField = `[${inputs[0].label}]`;
    const topicField = `[${inputs[1].label}]`;
    const targetField = `[${inputs[2].label}]`;
    const personField = `[${inputs[3].label}]`;
    const toneField = `[${inputs[4].label}]`;
    const formatField = `[${inputs[5].label}]`;
    const examplesField = `[${inputs[6].label}]`;
    const ctaField = `[${inputs[7].label}]`;
    const promptField = `[${inputs[8].label}]`;

    const callToActionValue =
      inputs[7].value.length > 0
        ? inputs[7].value
        : "There is no call to action on this prompt";

    const task = inputs[0].editable ? taskField : inputs[0].value;
    const topic = inputs[1].editable ? topicField : inputs[1].value;
    const target = inputs[2].editable ? targetField : inputs[2].value;
    const person = inputs[3].editable ? personField : inputs[3].value;
    const tone = inputs[4].editable ? toneField : inputs[4].value;
    const format = inputs[5].editable ? formatField : inputs[5].value;
    const examples = inputs[6].editable ? examplesField : inputs[6].value;
    const callToAction = inputs[7].editable ? ctaField : callToActionValue;
    const prompt = inputs[8].editable ? promptField : inputs[8].value;
    const feedback = state.feedback.input.value;

    return {
      task,
      topic,
      target,
      person,
      tone,
      format,
      examples,
      callToAction,
      prompt,
      feedback,
    };
  };

  const postSuperFetch = (data) => {
    setLoading(true);
    SuperFetchService.postSuperFetch(data).catch((err) => {
      setLoading(false);
      alert(err);
    });
  };

  const getTemplateFields = () => {
    const inputs = [...state.inputs];
    return inputs
      .filter((input) => input.editable)
      .map((input) => ({
        name: input.label,
        label: "",
        type: "text",
        options: "",
        isValidField: true,
        isValidLabel: true,
        validOptionsLength: true,
      }));
  };

  const resetFeedbackInput = () => {
    const updatedInput = {
      value: "",
      isValid: true,
    };

    changeFeedbackLiked(null);
    dispatch({ type: SET_FEEDBACK_CHANGES, payload: updatedInput });
  };

  const toggleFormActive = (active) => {
    dispatch({ type: SET_FEEDBACK_FORM, payload: active });
  };

  const toggleSmallDevice = (isSmall) => {
    dispatch({ type: SET_SMALL_DEVICE, payload: isSmall });
  };

  const enhanceSuperFetch = (data, assistant_id) => {
    const { prompt } = data;
    const request = [
      `Transform this lackluster prompt into a more effective and engaging question or statement: "${prompt}". Write the instruction to get the result, not the result itself. Consider the words within brackets will be changed to introduce user inputs in the instruction so don't modify it and use it where i can identify to insert the user inputs `,
    ];

    const requestData = {
      ...data,
      messages: request,
    };

    return new Promise((resolve, reject) => {
      SuperFetchService.getOutput({ requestData, assistant_id })
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return (
    <SuperFetchContext.Provider
      value={{
        ...state,
        setOutputs,
        setLoading,
        clearInputs,
        postSuperFetch,
        setFeedbackForm,
        toggleFormActive,
        changeInputValue,
        getTemplatePrompt,
        getTemplateFields,
        setEnhancedPrompt,
        toggleSmallDevice,
        enhanceSuperFetch,
        getMessagesHistory,
        resetFeedbackInput,
        getSuperFetchPrompt,
        changeFeedbackInput,
        changeFeedbackLiked,
        changeInputEditable,
        clearMessagesHistory,
        validateInputsLength,
        validateFeedbackInput,
        getSuperFetchFieldValues,
        getModifiedSuperFetchPrompt,
      }}
    >
      {children}
    </SuperFetchContext.Provider>
  );
};
