import { useContext } from "react";
import { AvatarsContext } from "../../context/AvatarsContext";
import { OrganizationsContext } from "../../context/OrganizationsContext";
import { AuthContext } from "../../context/AuthContext";
import { SuperFetchContext } from "../../context/SuperFetchContext";

const useSuperFetch = () => {

  const { user } = useContext(AuthContext);
  const { organization } = useContext(OrganizationsContext);
  const { super_fetch_avatar, saveAvatarFile } = useContext(AvatarsContext);

  const {
    postSuperFetch,
    getSuperFetchFieldValues,
  } = useContext(SuperFetchContext);

  const generateFetch = async (outputQty) => {
    const data = getSuperFetchFieldValues();
    const assistant_id = user.defaultAssistant?.id;
    const avatar_id = user.defaultAssistant?.avatar_id;
    const file = super_fetch_avatar?.file;
    if (file !== null && file !== undefined) {
      await saveAvatarFile(file, assistant_id, avatar_id);
    }

    if (organization?.organization_id !== null) {
      data.organization_id = organization.organization_id;
    }
    postSuperFetch({ ...data, outputs: outputQty, assistant_id });
  };

  return {
    generateFetch,
  };
};

export default useSuperFetch;
