import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const Pagination = ({
  size,
  itemsPerPage,
  totalItems,
  currentPage,
  onPageChange,
}) => {

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    if (currentPage > totalPages && totalPages > 0) {
      onPageChange(totalPages);
    }
    if(currentPage < 1) {
      onPageChange(1);
    }
 
  }, [currentPage, totalItems, itemsPerPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handleFirstPage = () => {
    onPageChange(1);
  };

  const handleLastPage = () => {
    onPageChange(totalPages);
  };

  const renderPageNumbers = () => {
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    if (endPage - startPage < 4) {
      if (startPage === 1) {
        endPage = Math.min(totalPages, startPage + 4);
      } else if (endPage === totalPages) {
        startPage = Math.max(1, endPage - 4);
      }
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers.map((page) => (
      <li key={page}>
        <button
          className={`btn ms-2 ${currentPage === page ? "btn-primary" : "bg-white border"
            }`}
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      </li>
    ));
  };

  return (
    <nav>
      <ul className="pagination d-flex mb-0 align-items-center overflow-hidden">
        <li>
          <button
            className="btn btn-round bg-white border me-2"
            onClick={handleFirstPage}
            disabled={currentPage === 1}
          >
            <i className="fa fa-angle-double-left" />
          </button>
        </li>
        <li>
          <button
            className="btn btn-round bg-white border"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            <i className="fa fa-chevron-left" />
          </button>
        </li>
        {size !== "sm" ? (
          renderPageNumbers()
        ) : (
          <li key={currentPage}>
            <button className="btn ms-2 btn-primary">{currentPage}</button>
          </li>
        )}
        <li>
          <button
            className="btn btn-round bg-white border ms-2"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <i className="fa fa-chevron-right" />
          </button>
        </li>
        <li>
          <button
            className="btn btn-round bg-white border ms-2"
            onClick={handleLastPage}
            disabled={currentPage === totalPages}
          >
            <i className="fa fa-angle-double-right" />
          </button>
        </li>
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
