import React, { useContext, useState } from "react";
import { ConversationsContext } from "../../context/ConversationsContext";
import useTranslations from "../../hooks/useTranslations";
import AvatarFileCard from "../avatars/AvatarFileCard";
import AvatarDeleteFile from "../avatars/AvatarDeleteFile";

export default function ConversationFilesModal() {
  const [newFile, setNewFile] = useState('');
  const [fileToDelete, setFileToDelete] = useState(null);
  const { conversation, saveConversationFile, spinner, getSingleConversation } =
    useContext(ConversationsContext);
  const translations = useTranslations();

  const renderFiles = () => {
    if (
      Array.isArray(conversation?.avatar?.avatar_files) &&
      conversation.avatar.avatar_files.length > 0
    ) {
      return conversation.avatar.avatar_files.map((file, index) => (
        <AvatarFileCard
          key={file.file_data?.file_id}
          fileName={file.file_data?.name}
          fileId={file.file_data?.file_id}
          type={file.file_data?.type}
          openaiFileId={file.openai_file_id}
          assistantId={conversation.avatar.assistant_id}
          onDelete={setFileToDelete}
        />
      ));
    }

    return translations.conversations.empty_files;
  };

  const renderDeleteFileFrom = () => {
    if (fileToDelete) {
      return (
        <AvatarDeleteFile
          fileName={fileToDelete.fileName}
          fileType={fileToDelete.fileType}
          deleteData={fileToDelete.deleteData}
          callback={() => {
            getSingleConversation(conversation.conversation_id);
            setFileToDelete(null);
          }}
        />
      );
    }
  };

  const fileTypes = ".docx, .pdf, .pptx, .txt";

  const handleSaveFile = () => {
    if (newFile instanceof File) {
      saveConversationFile(newFile, conversation.conversation_id, () => {
        setNewFile(null);
      });
    }
  };

  const renderIcon = () => {
    if (spinner) {
      return (
        <div
          className="spinner-border position-absolute top-0 bottom-0 
      end-0 m-auto me-3 "
        ></div>
      );
    }
    return (
      <i
        className=" fas fa-save  fs-6 ms-2 "
        style={{
          height: "max-content",
        }}
      />
    );
  };

  const renderFilesTab = () => {
    if(!fileToDelete) {
      return (
        <>
          <h3>{translations.conversations.files}</h3>
          {renderFiles()}
          <h3 className="mt-4">{translations.conversations.upload_new_file}</h3>
  
          <div className="mb-3">
            <span className="small d-block">
              {" "}
              <span className="bold small">
                {translations.general.file_types}:
              </span>
              {fileTypes}
            </span>
            <input
              accept={fileTypes}
              type="file"
              className="form-control"
              placeholder="Select a file"
              onChange={(e) => setNewFile(e.target.files[0])}
            />
          </div>
          <button
            disabled={!newFile || spinner}
            className="btn btn-outline-primary w-100 position-relative"
            onClick={handleSaveFile}
          >
            Upload File
            {renderIcon()}
          </button>
        </>
      );
    }
  };

  return (
    <div>
      {renderFilesTab()}
      {renderDeleteFileFrom()}
    </div>
  );
}
