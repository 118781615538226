import { getValue } from ".";

export const getTools = (appconfig, organization_id = null) => {
  const tabs = [];

  if (getValue(appconfig, "hashtags_enabled", "boolean")) {
    tabs.push({
      link: "/hashtags",
      name: { en: "Hashtags", es: "Hashtags" },
      icon: "fa fa-hashtag me-2",
    });
  }
  if (getValue(appconfig, "topics_enabled", "boolean")) {
    tabs.push({
      link: "/topics",
      name: { en: "Topics", es: "Temas" },
      icon: "fa fa-lightbulb me-2",
    });
  }
  if (getValue(appconfig, "chatwidget_enabled", "boolean") && organization_id === null) {
    tabs.push({
      link: "/chat-widget-generator",
      name: { en: "Chat Widget", es: "Chat Widget" },
      icon: "fa fa-comment me-2",
    });
  }

  return tabs;
};

export const getTabs = (appconfig, organization_id = null) => {
  const tabs = [];

  if (getValue(appconfig, "tutorials_enabled", "boolean")) {
    tabs.push({
      link: "/tutorials",
      name: { en: "Tutorials", es: "Tutoriales" },
      icon: "fa fa-chalkboard me-2",
    },);
  }

  if (getValue(appconfig, "avatars_enabled", "boolean")) {
    tabs.push({
      link: "/avatars",
      name: { en: "Avatars", es: "Avatares" },
      icon: "fa fa-users me-2",
    });
  }

  if (getValue(appconfig, "super_fetch", "boolean") && organization_id === null) {
    tabs.push({
      link: "/super-fetch",
      name: { en: "Super Fetch", es: "Super Fetch" },
      icon: "fa fa-bolt me-2",
    });
  }

  if (getValue(appconfig, "templates_enabled", "boolean")) {
    tabs.push({
      link: "/templates",
      name: { en: "Templates", es: "Plantillas" },
      icon: "fa fa-shapes me-2",
    });
  }

  if (getValue(appconfig, "documents_enabled", "boolean")) {
    tabs.push({
      link: "/documents",
      name: { en: "Documents", es: "Documentos" },
      icon: "fa fa-file-alt me-2",
    });
  }

  return tabs;
};

export const getOrganizationTabs = (appconfig) => {
  const tabs = {
    campaign: {},
    organization: [],
  };

  tabs.organization.push(
    {
      name: { en: "Settings", es: "Ajustes" },
      icon: "fa fa-cog",
      link: "/organizations/organization-settings",
    },
    {
      name: { en: "Users", es: "Usuarios" },
      icon: "fas fa-shield-alt",
      link: "organization/admin-panel",
    },
    // {
    //   name: { en: "Groups", es: "Grupos" },
    //   icon: "fas fa-users",
    //   link: "organization/groups",
    // }
    {
      name: { en: "Products", es: "Productos" },
      icon: "fas fa-shopping-cart",
      link: "organization/admin/products",
    }
  );

  if (getValue(appconfig, "campaigns_enabled", "boolean")) {
    tabs.campaign = {
      link: "/organization/campaigns",
      name: { en: "Campaigns", es: "Campañas" },
      icon: "fas fa-bullhorn me-2",
    };
  }

  return tabs;
};
