import { getArgs } from "../utils";
import api from "./api";
const route = "/invoices";

const InvoicesService = {
  getInvoices: (filters) =>
    api.get(
      `${route}/admin?${getArgs(
        filters
      )}`
    ),
  postInvoice: (invoice) => api.post(`${route}/admin`, { ...invoice }),
  putInvoice: (invoice) => api.put(`${route}/admin`, { ...invoice }),
  getMyInvoices: (filters) => api.get(`${route}/${getArgs(filters)}`)
};

export default InvoicesService;