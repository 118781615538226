import React, { useContext, useEffect } from "react";
import TemplateList from "../components/templates/TemplatesList";
import { TemplatesContext } from "../context/TemplatesContext";
import useTranslations from "../hooks/useTranslations";
import { setupTooltips } from "../utils";
import useTemplateFilters from "../hooks/templates/useTemplateFilters";
import { AppConfigContext } from "../context/AppConfigContext";

const Templates = () => {
  const translations = useTranslations();
  const { templates, getPublicTemplates } = useContext(TemplatesContext);
  const appconfig = useContext(AppConfigContext);

  useEffect(() => {
    setupTooltips();
  }, []);

  const { setFilterValue, filters } = useTemplateFilters();

  useEffect(() => {
    if (appconfig.templates_default_sort) {
      if (
        !["createdAt_desc", "updatedAt_desc"].includes(
          appconfig.templates_default_sort
        )
      ) {
        const currentTab =
          filters.templates_tab === "" ? "name" : filters.templates_tab;

        return setFilterValue(
          "sortBy",
          `${currentTab}_${appconfig.templates_default_sort}`
        );
      }
      setFilterValue("sortBy", appconfig.templates_default_sort);
    }
  }, [appconfig]);

  const fetchTemplates = (params) => {
    getPublicTemplates(params);
  };

  return (
    <div className="px-3 active-scroll-y h-100">
      <TemplateList
        size="lg"
        title={translations.templates.title}
        templates={templates}
        fetchTemplates={fetchTemplates}
        titleGradient
        defaultSelected={"type"}
      />
    </div>
  );
};

export default Templates;
