import React from "react";

const OrganizationAdminTable = ({ children, tableColumns }) => {
  const renderTableColumns = () => {
    return tableColumns.map((column) => {
      let textCenter = false;
      let textEnd = false;
      if (column.name === "Total Words" || column.name === "Last used") {
        textCenter = true;
      }

      if (column.name === "Actions") {
        textEnd = true;
      }

      return (
        <th
          className={`${
            textCenter ? "text-center" : textEnd ? "text-end" : ""
          }`}
          key={column.name}
        >
          {column.name}
        </th>
      );
    });
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>{renderTableColumns()}</tr>
        </thead>

        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default OrganizationAdminTable;
