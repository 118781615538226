const conversations = {
  spinner: "Hold tight, we're working on your instruction",
  empty: "You don't have any conversations.",
  search: "Search in conversations...",
  deleted: "Conversation deleted.",
  saved: "Conversation saved.",
  conversation_files: "Conversation Files",
  upload_new_file: "Upload new File",
  files: "Files",
  archived: "Conversation archived.",
  add: "Add Conversation",
  title: "Conversations",
  button: "Add",
  empty_files: "There are not files uploaded in this conversation.",
};
export default conversations;
