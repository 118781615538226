const auth = {
  error: "There was an error with your request, please try again later.",
  reset_password: "We've sent you a password reset email.",
  signup_reasons: ["Recommendations", "Facebook Ad", "Instagram Ad", "Other"],
  other_reason: "Tell us more",
  signup_reason: "How did you find out about us?",
  no_account: "Don't have an account yet?",
  has_account: "Already have an account?",
  confirm_password: "Confirm password.",
  forgot: "Forgot password?",
  signup: "Create an Account",
  deleted: "User deleted",
  last_name: "Last Name",
  phone: "Mobile Phone",
  password: "Password",
  saved: "User saved",
  company: "Company",
  email: "Email",
  login: "Login",
  show: "Show",
  hide: "Hide",
  name: "Name",
  user_type: "User Type",
  "error_404": "We did not found any account with this email. Could your account be under a different email?",
};
export default auth;
