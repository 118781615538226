import React, { Fragment, useContext, useEffect, useState } from "react";
import { AvatarsContext } from "../context/AvatarsContext";
import { MenuContext } from "../context/MenuContext";
import AvatarInputContext from "../components/avatars/AvatarInputContext";
import AvatarTraining from "../components/avatars/AvatarTraining";
import AvatarOutput from "../components/avatars/AvatarOutput";
import { AuthContext } from "../context/AuthContext";
import { MessagesContext } from "../context/MessagesContext";
import useTranslations from "../hooks/useTranslations";
import TemplateList from "../components/templates/TemplatesList";
import { TemplatesContext } from "../context/TemplatesContext";
import { navigate } from "@reach/router";
import AvatarFilesSection from "../components/avatars/AvatarFilesSection";
import useWindowSize from "../hooks/useWindowSize";
import ReactSwitch from "react-switch";
import { getValue } from "../utils";

const SingleAvatar = ({ avatar_id }) => {
  const [formActive, setFormActive] = useState(false);
  const [filesActive, setFilesActive] = useState(false);
  const [templatesActive, setTemplatesActive] = useState(false);
  const [isEditingContext, setIsEditingContext] = useState(false);
  const [formContainerActive, setFormContainerActive] = useState(true);

  const translations = useTranslations();

  const smallDevice = useWindowSize(1300);

  const { user } = useContext(AuthContext);
  const { setThreadMessages } = useContext(MessagesContext);
  const { selected, setSelected } = useContext(MenuContext);
  const { templates, getPublicTemplates } = useContext(TemplatesContext);

  const { avatar, spinner, setAvatar, saveAvatar, getSingleAvatar } =
    useContext(AvatarsContext);

  const avatarHasContext =
    avatar?.context !== null && avatar?.context?.length > 0;
  const ableToTrain =
    user.staff?.role === "admin" || user.user_id === avatar?.user_id;
  const beginTrainingActive =
    avatar?.context === null || avatar?.context.length === 0;

  useEffect(() => {
    return () => {
      setAvatar(null);
      setThreadMessages([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSingleAvatar(avatar_id, user.user_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar_id]);

  useEffect(() => {
    if (!smallDevice && ableToTrain) {
      setFormContainerActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smallDevice]);

  useEffect(() => {
    if (avatar && avatar !== null) {
      if (selected !== avatar.name) setSelected(avatar.name);
      if (formActive !== avatarHasContext) setFormActive(avatarHasContext);
      if (filesActive !== avatarHasContext) setFilesActive(avatarHasContext);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar]);

  const handleBeginTraining = () => {
    setIsEditingContext(true);
    setFormActive(true);
  };

  const cancelEditContext = () => {
    setIsEditingContext(false);
    if (avatarHasContext) {
      setFilesActive(true);
    } else {
      setFormActive(false);
      setFilesActive(false);
    }
  };

  const handleSaveContext = (value) => {
    saveAvatar({
      ...avatar,
      context: value,
    });
    if (!avatarHasContext) {
      setFilesActive(true);
    }
    setIsEditingContext(false);
  };

  const handleChangeSection = () => {
    setFormContainerActive(!formContainerActive);
  };

  const renderAvatarTraining = () => {
    if (beginTrainingActive && ableToTrain && !formActive) {
      return (
        <AvatarTraining
          avatarName={avatar?.name}
          handleBegin={handleBeginTraining}
        />
      );
    }
  };

  const renderFilesSection = () => {
    if (filesActive && ableToTrain) {
      return <AvatarFilesSection avatar={avatar} />;
    }
  };

  const renderForm = () => {
    let componentActive = true;
    if (!formActive) componentActive = false;
    if (smallDevice && !formActive) componentActive = false;
    if (componentActive && ableToTrain) {
      return (
        <>
          <AvatarInputContext
            isEditing={isEditingContext}
            setIsEditing={setIsEditingContext}
            handleCancel={cancelEditContext}
            handleSave={handleSaveContext}
            loading={spinner}
          />
          {getValue(avatar, "organization_id") && (
            <div className="row my-3 align-items-center">
              <div className="col-9">Set As Public Avatar</div>
              <div className="col-3 text-end">
                <ReactSwitch
                  checked={getValue(avatar, "public", "boolean")}
                  onChange={(checked) =>
                    saveAvatar({ ...avatar, public: checked })
                  }
                />
              </div>
            </div>
          )}
          {renderFilesSection()}
        </>
      );
    }
  };

  const handleSetTemplatesActive = () => {
    if (templatesActive) {
      setTemplatesActive(false);

      if (avatarHasContext) {
        setFormActive(true);
        setFilesActive(true);
      }
    } else {
      setFormActive(false);
      setFilesActive(false);
      setTemplatesActive(true);
    }
  };

  const handleToggleTemplates = () => {
    if (!smallDevice) {
      handleSetTemplatesActive();
    } else {
      if (templatesActive) {
        handleChangeSection();
        setTemplatesActive(false);

        if (avatarHasContext) {
          setFormActive(true);
          setFilesActive(true);
        }
      } else {
        handleChangeSection();
        setFormActive(false);
        setFilesActive(false);
        setTemplatesActive(true);
      }
    }
  };

  const renderOutput = () => {
    const componentActive = !(smallDevice && formContainerActive);
    return (
      <div
        className={`col-12 col-md-6 pb-3 h-100
        ${componentActive ? "" : "d-none"}`}
      >
        <AvatarOutput
          templatesActive={templatesActive}
          setTemplatesActive={handleToggleTemplates}
        />
      </div>
    );
  };

  const handleApply = (templateId) => {
    navigate(`/templates/apply-avatar/${templateId}/${avatar_id}`);
  };

  const fetchTemplates = (params) => {
    getPublicTemplates(params);
  };

  const renderTemplates = () => {
    if (templatesActive) {
      return (
        <div className="row">
          <TemplateList
            title={translations.templates.title}
            templates={templates}
            handleApply={handleApply}
            fetchTemplates={fetchTemplates}
            handleBackBtn={handleToggleTemplates}
            disableAddBtn
            filtersActive
          />
        </div>
      );
    }
  };

  const renderContent = () => {
    if (spinner) return <div className="ms-3 spinner-border" />;

    return (
      <div
        className="row mt-2 justify-content-center"
        style={{
          height: "calc(100% - 72px)",
        }}
      >
        {(ableToTrain || templatesActive) && (
          <div
            className={`col-12 col-md-6 active-scroll-y pb-3 d
            d-flex flex-column h-100 ${formContainerActive ? "" : "d-none"}`}
          >
            {renderAvatarTraining()}
            {renderForm()}
            {renderTemplates()}
          </div>
        )}
        {renderOutput()}
      </div>
    );
  };

  const renderChangeSection = () => {
    if (spinner) return "";

    let componentActive = false;
    if (smallDevice && ableToTrain) componentActive = true;
    if (componentActive) {
      return (
        <button
          type="button"
          className="btn btn-primary ms-auto small"
          onClick={handleChangeSection}
        >
          {formContainerActive ? (
            <Fragment>
              <span className="me-2 small hide-mobile">Conversation</span>
              <i className="fa fa-arrow-right"></i>
            </Fragment>
          ) : (
            <Fragment>
              <i className="fa fa-arrow-left"></i>
              <span className="ms-2 small hide-mobile">
                {templatesActive ? "Templates" : "Context"}
              </span>
            </Fragment>
          )}
        </button>
      );
    }
  };

  return (
    <div
      id="avatar"
      className="container-fluid py-1 pt-2 px-1 rounded-3 w-100 h-100 bg-white"
    >
      <div className="row">
        <div className="col-12 d-flex align-items-center">
          <h1 className={`mb-0 text-capitalize text-gradient d-inline-block`}>
            Avatars
          </h1>
          {renderChangeSection()}
        </div>
      </div>
      {renderContent()}
    </div>
  );
};

export default SingleAvatar;
