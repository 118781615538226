import React, { useContext, useEffect, useRef } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";

const SuperFetchSettings = () => {
  const textArea = useRef(null);

  const { super_fetch_prompt, setAppConfigProperty } =
    useContext(AppConfigContext);

  useEffect(() => {
    const setupHeight = () => {
      if (prompt === "") {
        textArea.current.style.height = "0px";
      } else {
        textArea.current.style.height = textArea.current.scrollHeight + "px";
      }
    };

    setupHeight();
  }, [textArea, super_fetch_prompt]);


  return (
    <div
      className="container-fluid d-flex flex-column py-3"
      style={{ height: "calc(100% - 40px)", overflowY: "auto" }}
    >
      <h4>Super Fetch Base Prompt</h4>
      <label className="mb-2">
        Must include variables: Task, Topic, Target, Person, Tone, Format,
        Examples, CTA and Prompt
      </label>
      <textarea
        rows="5"
        ref={textArea}
        className="form-control"
        value={super_fetch_prompt}
        onChange={(e) =>
          setAppConfigProperty("super_fetch_prompt", e.target.value)
        }
      />
    </div>
  );
};

export default SuperFetchSettings;
