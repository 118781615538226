import React, { useRef, useEffect, useContext, useState } from "react";
import { MessagesContext } from "../../context/MessagesContext";
import { setupTooltips } from "../../utils";
import useTranslations from "../../hooks/useTranslations";
import MessagesService from "../../services/MessagesService";
import { OrganizationsContext } from "../../context/OrganizationsContext";

const AvatarInputMessage = ({
  spinner,
  handleSubmit,
  value,
  setValue,
  templatesActive,
  setTemplatesActive,
  disableTemplatesBtn,
  addGenerateBtn,
  handleGenerateBtn,
}) => {
  const textArea = useRef(null);
  const translations = useTranslations();
  const [tokens, setTokens] = useState(0);

  const { setEnhancing, enhancing, enhanced, setEnhanced, maxAvatarTokens } =
    useContext(MessagesContext);
  const { organization } = useContext(OrganizationsContext);

  useEffect(() => {
    setupTooltips();

    return () => {
      setEnhanced(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const setupHeight = () => {
      if (prompt === "") {
        textArea.current.style.height = "0px";
      } else {
        textArea.current.style.height = textArea.current.scrollHeight + "px";
      }
    };

    setupHeight();
  }, [textArea]);

  useEffect(() => {
    setTokens(Math.round(value.length / 4));
  }, [value]);

  useEffect(() => {
    if (enhanced !== null && enhanced !== undefined) {
      setEnhancing(false);
      setValue(enhanced);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enhanced]);

  const handleEnhance = () => {
    const enhanceData = {
      content: `Transform this lackluster prompt into a more effective and engaging question or statement: "${value}". Write the instruction to get the result, not the result itself.`,
      enhance: true,
      stream: false,
    };

    if (
      organization.organization_id &&
      organization.organization_id !== undefined
    ) {
      enhanceData.organization_id = organization.organization_id;
    }

    setEnhancing(true);
    MessagesService.postMessage(enhanceData);
  };

  const renderEnhanceBtn = () => {
    if (String(value).length > 12) {
      return (
        <button
          type="button"
          onClick={handleEnhance}
          disabled={enhancing || tokens > maxAvatarTokens}
          className="btn btn-sm px-3 btn-outline-primary me-2"
        >
          {enhancing ? (
            <div className="spinner-border small"></div>
          ) : (
            <span>
              <i className="fas fa-magic"></i>
            </span>
          )}
        </button>
      );
    }
  };

  const handleTemplates = () => {
    setTemplatesActive(!templatesActive);
  };

  const renderTemplatesBtn = () => {
    if (!disableTemplatesBtn) {
      return (
        <button
          type="button"
          disabled={enhancing}
          onClick={handleTemplates}
          className={`btn btn-sm px-3 
          btn-outline-primary me-2`}
        >
          <i className="fas fa-shapes"></i>{" "}
        </button>
      );
    }
  };

  const renderBackBtn = () => {
    return (
      <button
        type="button"
        onClick={handleGenerateBtn}
        className={`${addGenerateBtn ? "" : "d-none"} btn btn-sm px-3 
        btn-outline-primary me-2`}
      >
        <i className="fa fa-arrow-left me-2"></i>
        Back
      </button>
    );
  };

  return (
    <div
      id="message-input"
      className="card p-2 w-100 position-absolute bottom-0 start-0 
      end-0 m-auto"
      style={{
        border: "0.5px solid rgb(229, 231, 235, 0.5)",
      }}
    >
      <form onSubmit={handleSubmit} className="row">
        <div className="px-0 col-12">
          <textarea
            type="text"
            ref={textArea}
            value={value}
            className="form-control"
            placeholder={translations.conversation.input.placeholder}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        </div>

        <div className="col-6 mt-2 px-0">
          {renderEnhanceBtn()}
          {renderTemplatesBtn()}
          {renderBackBtn()}

          <span
            className="ms-auto me-2 text-gradient"
            style={{ fontSize: "14px" }}
          >
            Tokens: {tokens} / {maxAvatarTokens}
          </span>
        </div>

        <div className="col-6 mt-2 px-0 text-end">
          <button
            type="submit"
            disabled={spinner || tokens > maxAvatarTokens}
            className="btn btn-primary position-relative"
          >
            {spinner ? (
              <div
                className="spinner-border"
                style={{ width: "25px", height: "25px" }}
              ></div>
            ) : (
              <span>
                <i className="fa fa-paper-plane me-1"></i>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AvatarInputMessage;
