export const feedbackForm = {
    liked: null,
    formActive: false,
    input: {
      value: "",
      isValid: true,
    },
  };

export const formInputs = [
    {
      label: "Task",
      value: "",
      type: "textarea",
      placeholder: "Write a summary about AI",
      description:
        "An example of the task component is an action verb such as write, generate, analyze, etc.",
      example: "",
      required: true,
      isValid: true,
      editable: false,
    },
    {
      label: "Topic",
      type: "textarea",
      value: "",
      placeholder: "The power of AI",
      description: "The Topic to consider when doing the task",
      required: true,
      isValid: true,
      editable: false,
    },
    {
      label: "Target Audience",
      type: "textarea",
      value: "",
      placeholder: "People who don't know how helpful IA could be.",
      description: "Describe who will use or read the output",
      required: true,
      isValid: true,
      editable: false,
    },
    {
      label: "Persona",
      type: "text",
      value: "",
      placeholder: "High School Teacher",
      example:
        "Example of a persona: 'Coaches, Content Creators, Stay-at-home moms, middle-aged men, Gen Xers, etc.'",
      description:
        "Describe the expert from whom you want the answer. This person could be famous and directly named or it could be more general like an Orthopedic Surgeon or a Fitness Expert.",
      required: true,
      isValid: true,
      editable: false,
    },
    {
      label: "Tone",
      type: "text",
      value: "",
      placeholder: "Enthusiastic",
      example:
        "Example of tone: 'Use casual, informal language that resonates with the target market'.",
      description:
        "Examples are casual, optimistic, formal, witty, dark, enthusiastic, confident, frustrated, angry, inquisitive, cheerful, etc.",
      required: true,
      isValid: true,
      editable: false,
    },
    {
      label: "Formula",
      type: "text",
      value: "",
      placeholder: "Presentation",
      description:
        "What is the output going to look like: an email, blog post, script, text, Instagram post, etc. You can also describe size, bullet point, narrative, 8th grade English (or other level or language) and other types of writing and speech.",
      required: true,
      isValid: true,
      editable: false,
    },
    {
      label: "Examples",
      type: "textarea",
      value: "",
      description:
        "Sample text(s) that you already like, such as an article written by the same persona you listed above. Anything that further explains the other categories within the SuperFetch with specifics.",
      placeholder: "Hello class today we will talk about...",
      required: false,
      isValid: true,
      editable: false,
    },
    {
      label: "Call to Action",
      type: "text",
      value: "",
      placeholder: "If you have any question, feel free to answer...",
      description:
        "The action you want the reader to perform. If there is no call action for this instruction, leave blank",
      example: "Click the link, register now, sign up today.",
      required: false,
      isValid: true,
      editable: false,
    },
    {
      label: "Manual Prompt",
      type: "textarea",
      value: "",
      placeholder: "",
      description:
        "Manual Instruction - Write your own instructor for super fetch to ensure the best output. This will be sent to our Super Fetch assistant along with the rest of the fields.",
      required: false,
      isValid: true,
      editable: false,
    },
  ];