import React, { useContext, useState } from "react";
import AvatarInputFiles from "./AvatarInputFiles";
import AvatarFileCard from "./AvatarFileCard";
import useTranslations from "../../hooks/useTranslations";
import { AvatarsContext } from "../../context/AvatarsContext";
import { ModalContext } from "../../context/ModalContext";
import EditAvatarFile from "./EditAvatarFile";

const AvatarFilesSection = ({ avatar }) => {
  const [editMode, setEditMode] = useState(false);
  const [filesTab, setFilesTab] = useState("files");
  const [editingFile, setEditingFile] = useState({});

  const translations = useTranslations();

  const { updateAvatarFile, spinner } = useContext(AvatarsContext);
  const { modalComponent } = useContext(ModalContext);

  const handleEdit = (file) => {
    // setEditMode(true);
    modalComponent(
      translations.avatars.edit_file,

      <EditAvatarFile
        file={{
          ...file,
          prev_name: file.file_data.name,
        }}
      />
    );
  };

  const renderEditableFileCards = () => {
    if (filesTab === "editable-files" && !editMode) {
      const currentEditableFiles = avatar?.avatar_files.filter(
        (file) => file.editable
      );
      if (currentEditableFiles?.length > 0) {
        return (
          <div className="px-0 col-12 h-100" style={{ overflowY: "auto" }}>
            {currentEditableFiles.map((file) => (
              <AvatarFileCard
                key={file.file_data?.file_id}
                fileName={file.file_data?.name}
                fileId={file.file_data?.file_id}
                openaiFileId={file.openai_file_id}
                assistantId={avatar.assistant_id}
                type={file.file_data?.type}
                content={file.content}
                onEdit={() => {
                  handleEdit(file);
                }}
                editable={true}
              />
            ))}
          </div>
        );
      }
      return (
        <div className="col-12 px-0" style={{ flex: 1 }}>
          <div className="card bg-light p-3">
            <h3 className="text-primary">
              {translations.avatar.editable_files_empty}
            </h3>
            <p>{translations.avatar.editable_files_description}</p>
          </div>
        </div>
      );
    }
  };

  const renderFileCards = () => {
    if (filesTab === "files" && !editMode) {
      const currentFiles = avatar?.avatar_files.filter(
        (file) => !file.editable
      );
      if (currentFiles?.length > 0) {
        return (
          <div className="px-0 col-12 h-100" style={{ overflowY: "auto" }}>
            {currentFiles.map((file) => (
              <AvatarFileCard
                key={file.file_data?.file_id}
                fileName={file.file_data?.name}
                fileId={file.file_data?.file_id}
                type={file.file_data?.type}
                openaiFileId={file.openai_file_id}
                assistantId={avatar.assistant_id}
              />
            ))}
          </div>
        );
      }
      return (
        <div className="col-12 px-0" style={{ flex: 1 }}>
          <div className="card bg-light p-3">
            <h3 className="text-primary">{translations.avatar.files_empty}</h3>
            <p>{translations.avatar.files_description}</p>
          </div>
        </div>
      );
    }
  };

  const renderAvatarInputFiles = () => {
    const editable = filesTab === "editable-files";
    const currentFileTypes = editable ? ".txt" : ".docx, .pdf, .pptx, .txt";

    if (!editMode) {
      return (
        <AvatarInputFiles editable={editable} fileTypes={currentFileTypes} />
      );
    }
  };

  const handleUpdateFile = async () => {
    await updateAvatarFile(editingFile);
    setEditMode(false);
  };

  const renderIcon = () => {
    if (spinner) {
      return (
        <div
          className="spinner-border position-absolute top-0 bottom-0 
      end-0 m-auto me-3"
        ></div>
      );
    }
    return (
      <i
        className="position-absolute top-0 bottom-0 
          end-0 fas fa-bookmark fs-4 m-auto me-3"
        style={{
          height: "max-content",
        }}
      />
    );
  };

  const renderAvatarSaveEdit = () => {
    if (editMode) {
      return (
        <div className="row mt-5" style={{ height: "40px" }}>
          <div className="col-6 ps-0">
            <button
              type="button"
              className="btn btn-link w-100 text-muted"
              onClick={() => setEditMode(false)}
            >
              Cancel
            </button>
          </div>

          <div className="col-6 pe-0">
            <button
              type="button"
              className="btn btn-primary w-100 position-relative"
              disabled={spinner}
              onClick={handleUpdateFile}
            >
              Save
              {renderIcon()}
            </button>
          </div>
        </div>
      );
    }
  };

  const renderEditFile = () => {
    if (editMode && editingFile) {
      return (
        <>
          <label className="mb-0 px-0">
            {translations.avatars.editable_file_name}
          </label>
          <input
            className="form-control sm"
            value={editingFile.file_data.name}
            type="text"
            style={{
              height: "30px",
            }}
            onChange={(e) => {
              const value = e.target.value;
              setEditingFile({
                ...editingFile,
                file_data: {
                  ...editingFile.file_data,
                  name: value,
                },
              });
            }}
          />

          <label className="mb-0 px-0">{translations.avatars.content}</label>
          <textarea
            rows="5"
            type="text"
            value={editingFile.content}
            className="form-control"
            style={{
              maxHeight: "calc(100% - 80px)",
            }}
            onChange={(e) => {
              const value = e.target.value;
              setEditingFile({
                ...editingFile,
                content: value,
              });
            }}
          />
        </>
      );
    }
  };

  return (
    <>
      <div
        className={`row mt-5 flex-column avatar-files-section ${
          editMode ? "edtiable" : ""
        }`}
      >
        <div className="col-12 px-0 h-100">
          <ul className="nav nav-tabs">
            <li
              onClick={() => {
                setFilesTab("files");
                setEditMode(false);
              }}
              className="nav-item cursor-pointer text-dark h-max-content px-0 disable-hover-bg"
            >
              <span
                className={`nav-link ${
                  filesTab === "files" ? "active text-primary" : ""
                }`}
              >
                {translations.avatar.files}
              </span>
            </li>
            <li
              onClick={() => {
                setFilesTab("editable-files");
                setEditMode(false);
              }}
              className="nav-item cursor-pointer text-dark h-max-content px-0 disable-hover-bg"
            >
              <span
                className={`nav-link ${
                  filesTab === "editable-files" ? "active text-primary" : ""
                }`}
              >
                {translations.avatar.editable_files}
              </span>
            </li>
          </ul>
          <div
            className="row overflow-y-auto"
            style={{ height: "calc(100% - 38.4px)" }}
          >
            {renderFileCards()}
            {renderEditableFileCards()}
            {renderEditFile()}
          </div>
        </div>
      </div>
      {renderAvatarInputFiles()}
      {renderAvatarSaveEdit()}
    </>
  );
};

export default AvatarFilesSection;
