import React, { useContext, useState, useEffect } from "react";
import useTranslations from "../../../hooks/useTranslations";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import moment from "moment";

const OrganizationPanelHeader = ({
  showDateFilters,
  showSearch,
  handleChange,
  onClick,
}) => {
  const { organization } = useContext(OrganizationsContext);
  const translations = useTranslations();
  const usersTranslations = translations.admin.users;

  const [query, setQuery] = useState("");
  const [startDate, setStartDate] = useState(
    moment().startOf("month").toISOString().split("T")[0] // Default to today's date
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").toISOString().split("T")[0] // Default to 30 days from today
  );

  useEffect(() => {
    if (typeof handleChange === "function") {
      handleChange({
        query,
        start_date: startDate,
        end_date: endDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, startDate, endDate]);

  return (
    <div>
      <div className="row align-items-center">
        {/* Organization Name */}
        <div className="col-12 col-md-4 px-0">
          <h2
            className="text-gradient h3 bold mb-0 d-inline-block"
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {organization?.name} Users
          </h2>
        </div>

        {/* Date Pickers and Query Input */}
        <div className="col-12 col-md-8 px-0 mt-2">
          {showDateFilters && (
            <div className="row mb-2">
              <div className="col-6">
                <input
                  type="date"
                  value={startDate}
                  className="form-control bg-light"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="col-6">
                <input
                  type="date"
                  value={endDate}
                  className="form-control bg-light"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row align-items-center my-2 justify-content-end">
        {showSearch && (
          <div className={onClick ? "col-10 col-md-8" : "col-12 ps-0"}>
            <input
              type="text"
              className="form-control bg-light"
              placeholder="Search..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
        )}

        {onClick && (
          <div className={`col-${showSearch ? "2" : "12"} col-md-4 text-right`}>
            <button onClick={onClick} className="btn btn-primary w-100 px-0">
              + <span className="hide-mobile">{usersTranslations.addBtn}</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganizationPanelHeader;
