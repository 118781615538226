import React, { useContext } from "react";
import { SuperFetchContext } from "../../context/SuperFetchContext";
import { ModalContext } from "../../context/ModalContext";
import ResetDefaultAvatar from "../user/ResetDefaultAvatar";

const SuperFetchActions = () => {
  const { feedback, loading, clearInputs } = useContext(SuperFetchContext);
  const { modalComponent } = useContext(ModalContext);

  const { formActive } = feedback;

  const handleConfirmReset = () => {
    modalComponent("Reset Super Fetch", <ResetDefaultAvatar />);
  };
  
  return (
    <div
      className={`d-flex w-100 align-items-end justify-content-end 
        ${formActive ? "d-none" : ""}`}
    >
      <div>
        <button
          type="button"
          disabled={loading}
          onClick={handleConfirmReset}
          className="btn ms-auto btn-outline-danger small me-2"
        >
          <i className="fas fa-sync small me-2" />
          Reset
        </button>
        <button
          type="button"
          disabled={loading}
          onClick={clearInputs}
          className="btn ms-auto btn-outline-primary small"
        >
          <i className="fas fa-trash small me-2" />
          Clean
        </button>
      </div>
    </div>
  );
};

export default SuperFetchActions;
