const templates = {
  all: "Todo",
  tag: "Etiqueta",
  type: "Tipo",
  back: "Regresar",
  sort: {
    last_created: "Más Reciente",
    last_used: "Último Usado",
  },
  field: {
    types: {
      text: "Texto",
      number: "Numero",
      select: "Select",
    },
  },
  confirm: "Confirmar",
  title: "Plantillas",
  apply: "Elige un Chat",
  apply_template: "Usar Plantilla",
  create: "Agregar",
  create_template: "Crear Template",
  search: "Buscar plantillas",
  saved: "Plantilla guardada",
  deleted: "Plantilla eliminada",
  favorites: "Mostrar favoritos",
  favorite_saved: "Favorito guardado",
  empty: "No hay plantillas disponibles para tu búsqueda",
  confirm_delete:
    "¿Estás seguro que quieres eliminar esta plantilla? Esta acción NO puede deshacerse.",
  card: {
    more: "Leer más",
    public: "Pública",
    apply: "Usar Plantilla",
    edit: "Editar Plantilla",
    delete: "Eliminar Plantilla",
  },
};

export default templates;
