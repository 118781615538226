import React, { useState, useContext, useEffect } from "react";
import { MessagesContext } from "../../context/MessagesContext";
import { ModalContext } from "../../context/ModalContext";
import useTranslations from "../../hooks/useTranslations";
import MarkdownPreview from "@uiw/react-markdown-preview";

const GeneratorMessageCard = ({ message, base_prompt, handleCallback }) => {
  const { saveMessage } = useContext(MessagesContext);
  const [editedText, setEditedText] = useState("");
  const [editMode, setEditMode] = useState(false);
  const { success } = useContext(ModalContext);
  const [copied, setCopied] = useState(false);

  const translations = useTranslations();
  const { formatDateTime } = translations;

  useEffect(() => {
    if (copied) {
      success(translations.general.copied);
    }
  }, [copied]);

  const handleCopy = () => {
    let input = document.createElement("textarea");
    input.value = message.content;
    input.id = "copy-input";
    document.body.appendChild(input);
    var copyText = document.getElementById("copy-input");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    navigator.clipboard.writeText(copyText.value).then(() => {
      setCopied(true);
    });
    input.remove();
  };

  const clearEdit = () => {
    setEditMode(false);
    setEditedText("");
  };

  const toggleFavorite = () => {
    let current = { ...message };
    current.favorite = !current.favorite;
    saveMessage(current, handleCallback);
    saveMessage(
      { favorite: current.favorite, message_id: current.user_message_id },
      handleCallback
    );
  };

  const handleEdit = () => {
    if (editMode) {
      clearEdit();
      return saveMessage({ ...message, content: editedText }, handleCallback);
    }
    setEditMode(true);
    setEditedText(message.content);
  };

  const renderPrompt = () => {
    if (message.prompt !== "" && message.prompt !== null) {
      let { prompt } = message;
      prompt = String(prompt).replace('Use English as the main language to do the next task: Generate 10 to 20 trending hashtags ideas for', "");
      prompt = String(prompt).replace('Use Español as the main language to do the next task: Generate 10 to 20 trending hashtags ideas for', "");
      prompt = String(prompt).replace(`Use English as the main language to do the next task: Generate 2 to 5 trending topic ideas for`, "");
      prompt = String(prompt).replace('Use Español as the main language to do the next task: Generate 2 to 5 trending topic ideas for', "");
      return <p className="fw-bold">{prompt}</p>;
    }
  };

  const renderMessage = () => {
    if (editMode) {
      return (
        <textarea
          rows="10"
          type="text"
          value={editedText}
          className="form-control mb-3"
          onChange={(e) => setEditedText(e.target.value)}
        />
      );
    }
    if (message.content && message.content !== null) {
      return <MarkdownPreview source={String(message.content)} />;
    }
  };

  return (
    <div className="col-12 bg-white mb-3">
      <div className="card bg-light output-card shadow-sm border p-4 ">
        {renderPrompt()}
        {renderMessage()}
        <div className="row align-items-center mt-3">
          <div className="col-12 col-md-6">
            <p className="small mb-0 mt-3 text-muted">
              {formatDateTime(message.createdAt)}
            </p>
          </div>
          <div className="col-12 col-md-6 text-end">
            {message.role !== "user" && (
              <div>
                {editMode && (
                  <button
                    onClick={clearEdit}
                    className="btn btn-sm btn-outline-danger me-2"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                )}
                <button
                  onClick={toggleFavorite}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={translations.generator.favorite}
                  className={`btn btn-sm btn-round btn-${
                    message.favorite ? "warning border" : "outline-dark"
                  } me-2`}
                >
                  <i className="fa fa-star"></i>
                </button>
                <button
                  onClick={handleEdit}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={translations.generator.edit}
                  className="btn btn-sm btn-round btn-outline-dark me-2"
                >
                  <i className={`fa fa-${editMode ? "save" : "edit"}`}></i>
                </button>
                <button
                  onClick={handleCopy}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={translations.general.copy}
                  className="btn btn-sm btn-round btn-outline-dark"
                >
                  <i className="fa fa-copy"></i>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratorMessageCard;
