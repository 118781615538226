import { AppConfigContext } from "../context/AppConfigContext";
import React, { useContext } from "react";
import parse from "html-react-parser";
import { Link } from "@reach/router";
import { getValue } from "../utils";
import "../css/landing.css";

const Landing = () => {
  const appconfig = useContext(AppConfigContext);

  const {
    landing_bg_className,
    high_ticket_offer_link,
    landing_text_className,
    high_ticket_offer_name,
  } = appconfig;

  const valid = (value) =>
    value !== "" && value !== null && value !== undefined;

  const renderTextValue = (key) => {
    const value = getValue(appconfig, key);
    if (value && value !== null) {
      if (String(value).includes("<")) {
        return parse(value);
      }
      return value;
    }
  };

  const renderBenefits = () => {
    const benefit1_title = getValue(appconfig, "benefit1_title");
    const benefit2_title = getValue(appconfig, "benefit2_title");
    const benefit3_title = getValue(appconfig, "benefit3_title");
    if (
      valid(benefit1_title) &&
      valid(benefit2_title) &&
      valid(benefit3_title)
    ) {
      return (
        <div>
          <h2 className={`text-center ${landing_text_className} my-5`}>
            Exclusive Benefits for {high_ticket_offer_name} Members
          </h2>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-4">
              <div className={`card mb-3 p-4 shadow ${landing_bg_className}`}>
                <div className="card-body p-4">
                  <i className="fas fa-2x text-gradient mb-4 fa-users"></i>
                  <h3 className={`${landing_text_className} bold`}>
                    {renderTextValue("benefit1_title")}
                  </h3>
                  <p className={`${landing_text_className}`}>
                    {renderTextValue("benefit1_description")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-4">
              <div className={`card mb-3 p-4 shadow ${landing_bg_className}`}>
                <div className="card-body p-4">
                  <i className="fas fa-2x text-gradient mb-4 fa-magic"></i>
                  <h3 className={`${landing_text_className} bold`}>
                    {renderTextValue("benefit2_title")}
                  </h3>
                  <p className={`${landing_text_className}`}>
                    {renderTextValue("benefit2_description")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-4">
              <div className={`card mb-3 p-4 shadow ${landing_bg_className}`}>
                <div className="card-body p-4">
                  <i className="fas fa-2x text-gradient mb-4 fa-users"></i>
                  <h3 className={`${landing_text_className} bold`}>
                    {renderTextValue("benefit3_title")}
                  </h3>
                  <p className={`${landing_text_className}`}>
                    {renderTextValue("benefit3_description")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid text-center pt-3">
            <a
              href={high_ticket_offer_link}
              className="btn btn-lg btn-primary px-5 bold"
              target="_blank"
              rel="noreferrer"
            >
              Sign Up for {high_ticket_offer_name}
            </a>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      id="landing"
      className={`container-fluid py-5 ${landing_bg_className}`}
    >
      <div className="container">
        <nav
          id="navbar"
          className={`navbar px-3 br-10 w-100 my-4 align-items-center mb-2 mt-0 py-2 shadow ${landing_bg_className}`}
        >
          <Link to="/auth" className="navbar-brand">
            <img
              className="d-inline-block"
              alt={getValue(appconfig, "app_name")}
              src={getValue(appconfig, "light_logo")}
              style={{ height: 60, width: "auto", objectFit: "contain" }}
            />{" "}
          </Link>
          <Link to="/auth" className="btn btn-primary">
            Get Started
          </Link>
        </nav>
      </div>

      <div className="container position-relative">
        <div className="row hero-section align-items-center mt-3">
          <div className="col-12 col-md-12 col-lg-7 mb-3">
            <h1 className={`${landing_text_className} display-2 bold`}>
              {renderTextValue("title")}
            </h1>
            <p className={`${landing_text_className} h3 fw-normal`}>
              {renderTextValue("description")}
            </p>
            <Link to="/auth" className="btn btn-lg px-5 py-2 mt-3 btn-primary">
              Get Started
            </Link>
          </div>
          <div className="col-12 col-md-12 col-lg-5 px-0 mb-3 position-relative">
            <img
              src={getValue(appconfig, "hero_img")}
              className="mw-100 w-100 d-block m-auto"
              alt="hero"
            />
          </div>
        </div>
      </div>

      {getValue(appconfig, "about") && (
        <div className="container mb-4">
          <div className={`card p-4 text-large ${landing_bg_className}`}>
            <h2 className={`${landing_text_className}`}>About Us</h2>
            <p className={`${landing_text_className}`}>
              {renderTextValue("about")}
            </p>
            <p className={`${landing_text_className}`}>
              {renderTextValue("call_to_action")}
            </p>
          </div>
        </div>
      )}

      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-4">
            <div className={`card mb-3 p-4 shadow ${landing_bg_className}`}>
              <div className="card-body p-4">
                {getValue(appconfig, "features_icons_enabled", "boolean") && (
                  <i className="fas fa-2x text-gradient mb-4 fa-shapes"></i>
                )}
                <h3 className={`${landing_text_className} bold`}>
                  {renderTextValue("feature1_title")}
                </h3>
                <p className={`${landing_text_className}`}>
                  {renderTextValue("feature1_description")}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-4">
            <div className={`card mb-3 p-4 shadow ${landing_bg_className}`}>
              <div className="card-body p-4">
                {getValue(appconfig, "features_icons_enabled", "boolean") && (
                  <i className="fas fa-2x text-gradient mb-4 fa-magic"></i>
                )}
                <h3 className={`${landing_text_className} bold`}>
                  {renderTextValue("feature2_title")}
                </h3>
                <p className={`${landing_text_className}`}>
                  {renderTextValue("feature2_description")}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-4">
            <div className={`card mb-3 p-4 shadow ${landing_bg_className}`}>
              <div className="card-body p-4">
                {getValue(appconfig, "features_icons_enabled", "boolean") && (
                  <i className="fas fa-2x text-gradient mb-4 fa-shapes"></i>
                )}
                <h3 className={`${landing_text_className} bold`}>
                  {renderTextValue("feature3_title")}
                </h3>
                <p className={`${landing_text_className}`}>
                  {renderTextValue("feature3_description")}
                </p>
              </div>
            </div>
          </div>
        </div>

        {renderBenefits()}
      </div>
    </div>
  );
};

export default Landing;
