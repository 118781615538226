import React from "react";
import { Link } from "@reach/router";
import useOrganization from "../../hooks/useOrganization";

const OrganizationPickerMobile = ({ toggleTabs, isOpen, toggleMenuButton }) => {
  const {
    organization,
    organizations,
    selectOrganization,
    setDefaultOrganization,
  } = useOrganization();

  const renderOrganizations = () => {
    return organizations?.map((organization) => (
      <div
        className="p-2 text-dark hover-accent no-decoration border-top border-bottom"
        key={organization?.organization_id}
        onClick={() => {
          selectOrganization(organization?.organization_id);
          toggleTabs("OrganizationPicker");
        }}
      >
        <div className="row w-100">
          <div className="col-1">
            <i className="fas fa-globe"></i>
          </div>
          <div className="col-11"> {organization.name}</div>
        </div>
      </div>
    ));
  };

  return (
    <div
      key={"organizations"}
      style={{ paddingLeft: "12px" }}
      className="text-dark hover-accent no-decoration border-top border-bottom"
    >
      <button
        className="btn text-start text-dark d-flex w-100 ps-2"
        onClick={() => toggleTabs("OrganizationPicker")}
      >
        <div className="col-1">
          <i className="fas fa-globe"></i>
        </div>
        <div className="col-11 d-flex justify-content-between">
          {organization ? organization.name : "Organization"}
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        </div>
      </button>
      {isOpen && renderOrganizations()}
      {isOpen && (
        <div
          className="p-2 text-dark hover-accent no-decoration border-top border-bottom"
          onClick={setDefaultOrganization}
          key="Personal"
        >
          <div className="row w-100">
            <div className="col-1">
              <i className="fas fa-user"></i>
            </div>
            <div className="col-11">Personal</div>
          </div>
        </div>
      )}
      {isOpen && (
        <div
          className="p-2 text-dark hover-accent no-decoration border-top border-bottom"
          onClick={() => {
            toggleTabs("OrganizationPicker");
            toggleMenuButton.current.click();
          }}
        >
          <Link to="/organizations/" className="dropdown-item  text-dark">
            <div className="row w-100">
              <div className="col-1">
                <i className="fas fa-plus"></i>
              </div>
              <div className="col-11">All</div>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

export default OrganizationPickerMobile;
