import React, { useContext, useEffect, useRef, useState } from "react";

import useTranslations from "../../hooks/useTranslations";
import { ModalContext } from "../../context/ModalContext";
import ShareMessageForm from "../messages/ShareMessage/ShareMessageForm";
import { MessagesContext } from "../../context/MessagesContext";
import moment from "moment";
import MarkdownPreview from "@uiw/react-markdown-preview";


const OutputCard = ({
  message_id,
  currentText,
  date
}) => {
  const [text, setText] = useState(currentText);
  const [editMode, setEditMode] = useState(false);
  const [editedText, setEditedText] = useState('');
  const [copied, setCopied] = useState(false);

  const translations = useTranslations();
  const contentRef = useRef(null);
  const { formatDateTime } = translations;

  const { modalComponent, clearModal, success } = useContext(ModalContext);
  const { saveMessage } = useContext(MessagesContext);

  useEffect(() => {
    if (copied) {
      success(translations.general.clipboard);
    }
  }, [copied]);

  const handleShare = () => {
    modalComponent("Share Content", 
      <ShareMessageForm 
        message={text} 
        handleCancel={clearModal} 
      />
    )
  }

  const clearEdit = () => {
    setEditMode(false);
    setEditedText('');
  }

  const handleCopy = () => {
    const content = contentRef.current;
    if (content) {
      content.style.backgroundColor = "white";
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(content);
      selection.removeAllRanges();
      selection.addRange(range);

      try {
        document.execCommand("copy");
      } catch (e) {
        alert("Unable to copy content to clipboard. Please copy manually.");
      } finally {
        selection.removeAllRanges();
        setCopied(true);
        content.style.backgroundColor = "inherit";
      }
    }
  }

  const renderShareBtn = () => {
    if(!editMode) {
      return(
        <button
          onClick={handleShare}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={translations.conversation.message.share}
          className="btn border-0 btn-round btn-sm btn-outline-dark me-1"
        >
          <i className="fa fa-share"></i>
        </button>
      )
    }
  }

  const renderClearEdit = () => {
    if(editMode) {
      return(
        <button
          onClick={clearEdit}
          className="btn border-0 btn-round btn-sm btn-outline-danger me-1"
        >
          <i className="fa fa-times"></i>
        </button>
      )
    }
  }

  const handleEdit = () => {
    if (editMode) {
      clearEdit();
      setText(editedText);
      
      return saveMessage({ 
        message_id, 
        content: editedText 
      });
    }
    setEditMode(true);
    setEditedText(text);
  }

  const renderEdit = () => {
    return(
      <button
        onClick={handleEdit}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={translations.conversation.message.edit}
        className="btn border-0 btn-round btn-sm btn-outline-dark me-1"
      >
        <i className={`fa fa-${editMode ? "save" : "edit"}`}></i>
      </button>
    )
  }

  const renderCopyBtn = () => {
    if(!editMode) {
      return(
        <button
          key="copy"
          onClick={handleCopy}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={translations.conversation.message.copy}
          className="btn border-0 btn-round btn-sm btn-outline-dark me-1"
        >
          <i className="fa fa-copy"></i>
        </button>
      )
    }
  }

  const renderOutputText = () => {
    if (editMode) {
      return (
        <textarea
          rows="10"
          type="text"
          value={editedText}
          className="form-control mb-3"
          style={{
            fontSize: '1em'
          }}
          onChange={(e) => setEditedText(e.target.value)}
        />
      );
    } else {
      return <MarkdownPreview source={text} />;
    }
  }

  const renderDate = () => {
    if(date) {
      return (
        <pre className="mb-0">{formatDateTime(date)}</pre>
      )
    }
  }

  return(
    <div className="card bg-accent-light p-3 mb-2">
      <pre 
        className="mb-2"
        style={{whiteSpace: 'pre-wrap'}} 
        ref={contentRef}
      >
        {renderOutputText()}
      </pre>

      <div className="row">
        <div className="col-6 mt-auto">
          {renderDate()}
        </div>

        <div className="col-6 text-end">
          {renderShareBtn()}
          {renderClearEdit()}
          {renderEdit()}
          {renderCopyBtn()}
        </div>
      </div>


    </div>
 
  )
};

export default OutputCard;