
import { useContext } from "react";
import { TemplatesContext } from "../../context/TemplatesContext";
import { updatePromptValues } from "../../utils/templates";

const useTemplatePrompt = () => {
  const { template } = useContext(TemplatesContext);

  const getTemplatePrompt = () => {
    let content = template?.content;
    template?.fields?.forEach((field) => {
      content = updatePromptValues(content, field.name, field.value);
    });

    return content;
  }

  return getTemplatePrompt;
}

export default useTemplatePrompt;