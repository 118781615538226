import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { CustomerContext } from "../../context/CustomerContext";
import { SuperFetchContext } from "../../context/SuperFetchContext";

const ResetDefaultAvatar = () => {
  const { spinner, resetDefaultAvatar } = useContext(CustomerContext);
  const { clearMessagesHistory } = useContext(SuperFetchContext);
  const { clearModal } = useContext(ModalContext);

  const handleReset = () => {
    resetDefaultAvatar(clearModal);
    clearMessagesHistory();
  };
  return (
    <div>
      <p>Are you sure you want to reset your Super Fetch?</p>
      <p>
        This will clear your output history and prevent Super Fetch from
        learning from previous responses..
      </p>
      <p>
        This is helpful when when you're switching topics, tasks or persona.
      </p>
      <div className="row align-items-center">
        <div className="col-6">
          <button
            disabled={spinner}
            onClick={clearModal}
            className="btn w-100 text-muted"
          >
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button
            className="btn w-100 btn-danger"
            onClick={handleReset}
            disabled={spinner}
          >
            {spinner ? <div className="spinner-border" /> : "Reset"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetDefaultAvatar;
