import React from "react";
import useTranslations from "../../hooks/useTranslations";
import SubmitButton from "./SubmitButton";

const FormActions = ({ disabled, loading, handleSubmit, handleCancel }) => {
  const translations = useTranslations();

  return (
    <div className="row my-3">
      <div className="col-6">
        <button
          type="button"
          onClick={handleCancel}
          className="btn w-100 text-muted"
        >
          {translations.general.cancel}
        </button>
      </div>
      <div className="col-6">
        <SubmitButton disabled={loading || disabled} onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default FormActions;
