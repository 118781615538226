import React, { useContext, useEffect, useState } from "react";
import { SuperFetchContext } from "../../context/SuperFetchContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import { AuthContext } from "../../context/AuthContext";
import LoadingCard from "../global/LoadingCard";
import OutputCard from "../global/OutputCard";

const SuperFetchOutputContainer = () => {
  const [history, setHistory] = useState(false);
  const {
    outputs,
    loading,
    smallDevice,
    messages_history,
    getMessagesHistory,
  } = useContext(SuperFetchContext);
  const { user } = useContext(AuthContext);
  const { app_name } = useContext(AppConfigContext);

  useEffect(() => {
    getMessagesHistory(user?.defaultAssistant?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputs]);

  const renderLoading = () => {
    if (loading) {
      return <LoadingCard />;
    }
  };

  const renderOutputs = () => {
    if (history) {
      if (messages_history.length === 0) {
        return <p className="px-3">No history available</p>;
      }
      return messages_history
        ?.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
        .map((output) => (
          <OutputCard
            key={output.message_id}
            currentText={output.content}
            message_id={output.message_id}
            date={output.updatedAt}
          />
        ));
    }
    return outputs.map((output) => (
      <OutputCard
        key={output.message_id}
        currentText={output.content[0].text.value}
        message_id={output.message_id}
      />
    ));
  };

  return (
    <div
      className={`col-12 col-xl-6 p-4 pt-0 ${
        smallDevice ? "h-50 pt-0 px-0" : "h-100"
      }`}
    >
      <div className="card bg-light h-100" style={{ overflow: "hidden" }}>
        <div className="card-header">
          <div className="row">
            <div className="col-6">
              <h3 className="m-0">{app_name}</h3>
            </div>

            <div className="col-6 text-end">
              <button
                type="button"
                className={`btn small d-flex align-items-center ms-auto
                  ${history ? "btn-primary" : "btn-outline-primary"}
                `}
                onClick={() => {
                  if (!history) getMessagesHistory(user.defaultAssistant.id);
                  setHistory(!history);
                }}
              >
                <i className="fas fa-clock fs-6 me-2" />
                History
              </button>
            </div>
          </div>
        </div>

        <div className="card-body" style={{ overflowY: "auto" }}>
          {renderLoading()}
          {renderOutputs()}
        </div>
      </div>
    </div>
  );
};

export default SuperFetchOutputContainer;
