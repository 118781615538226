import { Link, navigate } from "@reach/router";
import React, { useContext, useEffect, useState } from "react";
import useTranslations from "../../hooks/useTranslations";
import { ProductsContext } from "../../context/ProductsContext";
import Switch from "react-switch";
import { getValue } from "../../utils";
import moment from "moment";
import ProductSubscriptionsOptions from "./ProductSubscriptionOptions";
import ReactSwitch from "react-switch";
import { StripesContext } from "../../context/StripesContext";
import SearchableSelect from "../global/SearchableSelect";
import { ModalContext } from "../../context/ModalContext";
import { OrganizationsContext } from "../../context/OrganizationsContext";

const ProductForm = ({ product_id }) => {
  const [useExistingStripeData, setUseExistingStripeData] = useState(false);
  const [freeTrialProp, setFreeTrialProp] = useState("free_trial_length");
  const { organization } = useContext(OrganizationsContext);
  const [unlimited, setUnlimited] = useState(false);
  const translations = useTranslations();

  const {
    spinner,
    product,
    setProduct,
    saveProduct,
    createProduct,
    getSingleProduct,
    setProductProperty,
  } = useContext(ProductsContext);
  const {
    prices,
    products,
    getStripeProducts,
    getStripeProductPrices,
    extractStripePriceName,
  } = useContext(StripesContext);
  const { alert } = useContext(ModalContext);

  useEffect(() => {
    if (isNaN(product_id)) {
      createProduct();
    } else {
      getSingleProduct(product_id);
    }

    return () => {
      setProduct(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (unlimited && product?.word_amount !== null) {
      setProductProperty("word_amount", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unlimited]);

  useEffect(() => {
    if (product?.word_amount === null && !unlimited) {
      setUnlimited(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.word_amount]);

  useEffect(() => {
    if (useExistingStripeData) getStripeProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useExistingStripeData]);

  useEffect(() => {
    if (product?.stripe_product_id) {
      getStripeProductPrices(product.stripe_product_id);
      if (!useExistingStripeData) setUseExistingStripeData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.stripe_product_id]);

  const validateProduct = (product) => {
    let errors = [];

    // Check if all required fields are present using translations for messages
    if (!getValue(product, "name")) {
      errors.push(translations.products.invalid_name);
    }

    if (!getValue(product, "description")) {
      errors.push(translations.products.invalid_description);
    }

    const price = getValue(product, "price");
    if (typeof parseFloat(price) !== "number") {
      errors.push(translations.products.invalid_price);
    }

    const wordAmount = getValue(product, "word_amount");
    if (
      (typeof parseInt(wordAmount) !== "number" || wordAmount <= 0) &&
      !unlimited
    ) {
      errors.push(translations.products.invalid_word_amount);
    }

    // Check if stripe_product_id exists, then stripe_price_id must also exist
    // if (getValue(product, 'stripe_product_id') && !getValue(product, 'stripe_price_id')) {
    //   errors.push(translations.products.invalid_stripe_price_id);
    // }

    // Check if stripe_price_id exists, then subscription_interval and subscription_period must also exist
    // if (getValue(product, 'stripe_price_id')) {
    //   if (!getValue(product, 'subscription_interval')) {
    //     errors.push(translations.products.invalid_subscription_interval);
    //   }
    //   if (!getValue(product, 'subscription_period')) {
    //     errors.push(translations.products.invalid_subscription_period);
    //   }
    // }

    // If there are any errors, show them in an alert and return false
    if (errors.length > 0) {
      alert("Validation Errors:\n" + errors.join("\n"));
      return false;
    }

    // If all validations pass
    return true;
  };

  const handleCallback = () => {
    navigate("/admin/products");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (organization && organization !== null) {
      product.organization_id = organization?.organization_id;
    }
    if (validateProduct(product)) saveProduct(product, handleCallback);
  };

  const addDefaultPeriod = () => {
    setProductProperty("subscription_period", "month");
    setProductProperty("subscription_interval", "1");
  };

  const renderSubscriptionOpts = () => {
    if (getValue(product, "is_subscription", "boolean")) {
      return <ProductSubscriptionsOptions />;
    }
  };

  const renderStripeOptions = () => {
    if (useExistingStripeData) {
      return (
        <div className="row mb-3">
          <div className="col-6 ps-0">
            <label className="bold mb-2">
              {translations.products.stripe_product_id}
            </label>
            {Array.isArray(products) ? (
              <SearchableSelect
                itemValue={"id"}
                items={[
                  {
                    name: "Create a New Product on Stripe",
                    id: null,
                  },
                  ...products,
                ]}
                itemText={"name"}
                value={getValue(product, "stripe_product_id")}
                modifier={({ value }) =>
                  setProductProperty("stripe_product_id", value)
                }
              />
            ) : (
              <div className="spinner-border spinner-border-sm" />
            )}
          </div>
          <div className="col-6 pe-0">
            <label className="bold mb-2">
              {translations.products.stripe_price_id}
            </label>
            {Array.isArray(prices) ? (
              <SearchableSelect
                itemValue={"id"}
                itemText={"name"}
                modifier={({ value }) =>
                  setProductProperty("stripe_price_id", value)
                }
                value={getValue(product, "stripe_price_id")}
                items={[
                  {
                    name: "Leave Blank",
                    id: "",
                  },
                  {
                    name: "Create New Price on Stripe",
                    id: null,
                  },
                  ...prices.map((price) => ({
                    ...price,
                    name: extractStripePriceName(price),
                  })),
                ]}
              />
            ) : getValue(product, "stripe_product_id") !== "" ? (
              <div className="spinner-border spinner-border-sm" />
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
  };

  const renderFreeTrialOpts = () => {
    const free_trial_value =
      freeTrialProp === "free_trial_end"
        ? moment(product.free_trial_end).utc().format("YYYY-MM-DD")
        : product.free_trial_length;

    if (getValue(product, "is_subscription", "boolean")) {
      return (
        <div className="row align-items-center mb-2">
          <label className="px-0">
            {translations.products.free_trial_period}
          </label>
          <div className="col-12 col-md-2 mb-3 ps-0">
            <select
              value={freeTrialProp}
              className="form-control"
              onChange={(e) => setFreeTrialProp(e.target.value)}
            >
              <option value="free_trial_length">Days</option>
              <option value="free_trial_end">Date</option>
            </select>
          </div>
          <div className="col-12 col-md-10 mb-3">
            <input
              type={freeTrialProp === "free_trial_length" ? "number" : "date"}
              className="form-control"
              placeholder="Leave unfulfilled to disable"
              value={getValue({ free_trial_value }, "free_trial_value")}
              onChange={(e) =>
                setProductProperty(freeTrialProp, e.target.value)
              }
            />
          </div>
        </div>
      );
    }
  };

  const renderForm = () => {
    if (product && product !== null) {
      return (
        <form onSubmit={handleSubmit}>
          <label>{translations.products.name}</label>
          <input
            type="text"
            className="form-control mb-3"
            value={product.name}
            onChange={(e) => setProductProperty("name", e.target.value)}
          />
          <label>{translations.products.description}</label>
          <textarea
            className="form-control mb-3"
            value={product.description}
            onChange={(e) => setProductProperty("description", e.target.value)}
          />
          <label>{translations.products.short_description}</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(product, "short_description")}
            onChange={(e) =>
              setProductProperty("short_description", e.target.value)
            }
          />
          <div className="row">
            <div className="col-6 ps-0">
              <label>{translations.products.price}</label>
              <input
                type="number"
                className="form-control mb-3"
                value={getValue(product, "price")}
                onChange={(e) => setProductProperty("price", e.target.value)}
              />
            </div>
            <div className="col-6 pe-0">
              <label>{translations.products.sale_price}</label>
              <input
                type="number"
                className="form-control mb-3"
                value={getValue(product, "sale_price")}
                onChange={(e) =>
                  setProductProperty("sale_price", e.target.value)
                }
              />
            </div>
          </div>
          <div className="row">
            <h4 className="bold px-0 pb-2 mb-3 border-bottom">
              {translations.products.word_amount}
            </h4>
            <div className="col-6 ps-0">
              <div className="row">
                <div className="col-4 ps-0">
                  <label className="d-block">Unlimited</label>
                  <ReactSwitch checked={unlimited} onChange={setUnlimited} />
                </div>
                <div className="col-8 pe-0">
                  {!unlimited && (
                    <div>
                      <label className="d-block">Fixed Amount</label>
                      <input
                        type="number"
                        className="form-control mb-3"
                        value={getValue(product, "word_amount")}
                        onChange={(e) =>
                          setProductProperty("word_amount", e.target.value)
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 pe-0">
              <label>{translations.products.free_trial_words}</label>
              <input
                type="number"
                className="form-control mb-3"
                value={getValue(product, "free_trial_words")}
                onChange={(e) =>
                  setProductProperty("free_trial_words", e.target.value)
                }
              />
            </div>
          </div>

          <h4 className="border-bottom pb-2">Subscription</h4>
          <div className="row mb-2">
            <div className="col-6 ps-0">
              <label>{translations.products.is_subscription}</label>
            </div>
            <div className="col-6">
              <Switch
                checked={getValue(product, "is_subscription", "boolean")}
                onChange={(checked) => {
                  setProductProperty("is_subscription", checked);
                  if (checked) addDefaultPeriod();
                }}
              />
            </div>
          </div>
          {renderSubscriptionOpts()}
          {renderFreeTrialOpts()}

          <h4 className="border-bottom pb-2">Stripe</h4>
          <div className="row my-3 align-items-center">
            <div className="col-6 ps-0">
              <label>Use Existing Stripe Data</label>
            </div>
            <div className="col-6 pe-0">
              <ReactSwitch
                checked={useExistingStripeData}
                onChange={setUseExistingStripeData}
              />
            </div>
          </div>
          {renderStripeOptions()}

          <h4 className="border-bottom pb-2">Lead Connector</h4>

          <label>Webhook URL</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(product, "webhook_url")}
            onChange={(e) => setProductProperty("webhook_url", e.target.value)}
          />

          <label>Product SKU</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(product, "product_sku")}
            onChange={(e) => setProductProperty("product_sku", e.target.value)}
          />

          <h4 className="border-bottom pb-2">Limits</h4>

          <div className="row">
            <div className="col-6 ps-0">
              <label>{translations.products.user_limit}</label>
              <input
                type="number"
                className="form-control mb-3"
                value={getValue(product, "user_limit")}
                onChange={(e) =>
                  setProductProperty("user_limit", e.target.value)
                }
              />
            </div>
            <div className="col-6 pe-0">
              <label>{translations.products.limit_per_user}</label>
              <input
                type="number"
                className="form-control mb-3"
                value={getValue(product, "limit_per_user")}
                onChange={(e) =>
                  setProductProperty("limit_per_user", e.target.value)
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <button className="btn btn-primary">
                {spinner ? (
                  <div className="spinner-border"></div>
                ) : (
                  translations.admin.users.saveBtn
                )}
              </button>
            </div>
            <div className="col-6 text-right">
              <Link
                to="/myadmin/customers"
                className="btn btn-link text-secondary"
              >
                {translations.admin.users.cancelBtn}
              </Link>
            </div>
          </div>
        </form>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return <div>{renderForm()}</div>;
};

export default ProductForm;
