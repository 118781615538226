const purchases = {
  purchases: "Purchases",
  date: "Date",
  type: "Type",
  total: "Total",
  status: "Status",
  product: "Product",
  actions: "Actions",
  customer: "Customer",
  discount: "Discount",
  expiration: "Expires on",
  empty: "You haven't made any purchases",
  numberOfDays: "Number of Days",
  purchaseTotal: "Total Amount",
  isGift: "Is it a Gift?",
  payMentMethod: "Payment Method",
  editPurchase: "Edit Purchase",
  expirationDays: "Expiration Days",
  totalAmount: "Total Amount",
  paymentMethod: "Payment Method",
  cancel: "Cancel",
  savePurchase: "Save Purchase",
  active: "Active",
  completed: "Completed",
  pending: "Pending",
  cancelled: "Cancelled",
  revoked: "Revoked",
  giveAccess: "Give Access",
  cancelMessage: `If you Cancel the purchase, the customer will enjoy their benefits until
      the expiration date and no additional recurring charges will be made.
      This Action CANNOT be undone.`,
  revokeMessage: `If you Revoke the purchase, the customer will no longer have access to
      the benefits of this purchase immediately.`,
  activateMessage: `If you activate the purchase, the customer will receive access to TBM Online
      and the amount of credits included in the package immediately.`,
};
export default purchases;
