import CreatableSelect from "react-select/creatable";
import React, { useContext, useState, useEffect } from "react";
import ShareToPlatformCard from "./ShareToPlatformCard";
import { AuthContext } from "../../../context/AuthContext";
import useTranslations from "../../../hooks/useTranslations";
import { AvatarsContext } from "../../../context/AvatarsContext";
import { MessagesContext } from "../../../context/MessagesContext";
import useFilesConverter from "../../../hooks/global/useFilesConverter";
import ReactSwitch from "react-switch";
import { ModalContext } from "../../../context/ModalContext";
import MessagesService from "../../../services/MessagesService";

const ShareMessageForm = ({ message, handleCancel }) => {
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [editableFileName, setEditableFileName] = useState(
    `message-${message.message_id}`
  );
  const { shareMessageContent } = useContext(MessagesContext);
  const { clearModal } = useContext(ModalContext);
  const { linkedPlatforms, user } = useContext(AuthContext);
  const { avatars, getAvatars, saveAvatar, saveAvatarEditableFile, spinner } =
    useContext(AvatarsContext);

  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [saveAsContext, setSaveAsContext] = useState(false);
  const translations = useTranslations();

  const { export2Doc, export2pdf } = useFilesConverter("");

  useEffect(() => {
    getAvatars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShare = () => {
    if (selectedPlatform) {
      shareMessageContent({ ...message, platform: selectedPlatform.name });
    }
  };

  const renderLinkedPlatforms = () => {
    if (!linkedPlatforms || selectedPlatform) return null;
    return linkedPlatforms.map((platform) => (
      <ShareToPlatformCard
        key={platform.name}
        platform={platform}
        setPlatform={setSelectedPlatform}
      />
    ));
  };

  const handleAddAvatarFile = async () => {
    if (selectedAvatar) {
      if (saveAsContext) {
        const avatarContext = `${selectedAvatar.context}\n${message.content}`;
        await saveAvatar({
          ...selectedAvatar,
          context: avatarContext,
        });
        return;
      }

      if (!selectedAvatar.avatar_id) {
        return saveAvatar(
          {
            ...selectedAvatar,
          },
          (newAvatar) => {
            const assistant_id = newAvatar.assistant_id;
            const avatar_id = newAvatar.avatar_id;
            const text = message.content;

            saveAvatarEditableFile({
              text,
              fileName: editableFileName,
              assistant_id,
              avatar_id,
            });
          }
        );
      }
      const assistant_id = selectedAvatar.assistant_id;
      const avatar_id = selectedAvatar.avatar_id;
      const text = message.content;

      await saveAvatarEditableFile({
        text,
        fileName: editableFileName,
        assistant_id,
        avatar_id,
      });

      clearModal();
    }
  };

  const renderShareOptions = () => {
    if (selectedPlatform) return null;
    if (Array.isArray(avatars)) {
      const options = avatars.map((current) => {
        return {
          label: current.name,
          value: current.avatar_id,
        };
      });

      return (
        <>
          <div
            className="card position-relative bg-light mb-3 my-3"
            style={{ height: "100px" }}
          >
            <div className="card-body pb-2 position-relative d-flex flex-column align-items-start">
              <h3 className="m-0 p-0">Word</h3>
            </div>
            <button
              type="button"
              className="btn btn-primary btn-view"
              onClick={() => export2Doc("document", message.content)}
            >
              <i className="fa fa-share"></i>
            </button>
          </div>
          <div
            className="card position-relative bg-light mb-3 my-3"
            style={{ height: "100px" }}
          >
            <div className="card-body pb-2 position-relative d-flex flex-column align-items-start">
              <h3 className="m-0 p-0">PDF</h3>
            </div>
            <button
              type="button"
              disabled={loadingPdf}
              className="btn btn-primary btn-view p-0" 
              onClick={() => {
                setLoadingPdf(true);
                MessagesService.getMessagePdF(message.content).then(
                  (response) => {
                    setLoadingPdf(false);
                    const blob = response.data; // El archivo PDF estará en response.data
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob); // Crear URL para el Blob
                    link.download = "documento.pdf"; // Nombre del archivo para la descarga
                    link.click(); // Forzar la descarga
                  }
                );
              }}
            >
              {loadingPdf ? (
                <div className="spinner-border spinner-border-sm"></div>
              ) : (
                <i className="fa fa-share"></i>
              )}
            </button>
          </div>
          <div
            className="card position-relative bg-light mb-3 my-3"
            style={{ height: "max-content" }}
          >
            <div className="card-body pb-2 position-relative d-flex flex-column align-items-start">
              <h3 className="m-0 p-0">{translations.avatars.add_context}</h3>
              <div className="row w-75 justify-content-between mx-0 my-2">
                <label className="p-0 w-max-content">
                  {translations.avatars.save_as_context}
                </label>
                <ReactSwitch
                  className="w-max-content p-0"
                  checked={saveAsContext}
                  onChange={setSaveAsContext}
                />
              </div>

              <label>{translations.avatars.select_avatar}</label>
              <CreatableSelect
                className="basic-single w-75 mt-2 px-0"
                classNamePrefix="select an avatar"
                isSearchable
                formatCreateLabel={(inputValue) =>
                  `${translations.avatars.create}: "${inputValue}"`
                }
                onChange={(newValue) => {
                  if (newValue.__isNew__) {
                    return setSelectedAvatar({
                      name: newValue.value,
                      user_id: user.user_id,
                      context: "",
                    });
                  }
                  const selected = avatars.find((obj) => {
                    return obj.avatar_id === newValue.value;
                  });

                  setSelectedAvatar(selected);
                }}
                name="color"
                options={options}
              />
              {!saveAsContext && (
                <>
                  <label className="mt-2">
                    {translations.avatars.editable_file_name}
                  </label>
                  <input
                    className="form-control mt-1 px-0 w-75 bg-white border-1 border"
                    type="text"
                    disabled={saveAsContext}
                    value={editableFileName}
                    onChange={(e) => setEditableFileName(e.target.value)}
                  />
                </>
              )}
            </div>

            <button
              type="button"
              disabled={!selectedAvatar || spinner}
              className="btn btn-primary btn-view p-0"
              onClick={handleAddAvatarFile}
            >
              {spinner ? (
                <div className="spinner-border spinner-border-sm"></div>
              ) : (
                <i className="fa fa-share"></i>
              )}
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <div>
      {selectedPlatform ? (
        <div className="mb-3">
          <p>
            Are you sure you want to share this content to{" "}
            <b>{selectedPlatform.title}</b>?
          </p>
          <div className="my-4 card bg-light p-3">{message?.content}</div>
        </div>
      ) : (
        <div className="mb-3">
          <p>Share to which platforms?</p>
          {renderShareOptions()}
        </div>
      )}
    </div>
  );
};

export default ShareMessageForm;
