import React, { useRef, useContext, useState } from "react";
import { navigate } from "@reach/router";
import { getValue, hasNotch } from "../../utils";
import { MenuContext } from "../../context/MenuContext";
import { AuthContext } from "../../context/AuthContext";
import useTranslations from "../../hooks/useTranslations";
import useOrganization from "../../hooks/useOrganization";
import { CapacitorContext } from "../../context/CapacitorContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import OrganizationPickerMobile from "../menu/OrganizationPickerMobile";
import { OrganizationsContext } from "../../context/OrganizationsContext";

const MenuMobile = () => {
  const toggleMenuButton = useRef(null);
  const translations = useTranslations();
  const { lang } = translations;

  const { role } = useOrganization();

  const currentUserRole = role;
  const isOrganizationAdmin = currentUserRole === "Member" ? false : true;

  const { signOut, user } = useContext(AuthContext);
  const appconfig = useContext(AppConfigContext);
  const {
    userSettingTabs,
    organizationTabs,
    toolsTabs,
    adminTabs,
    selected,
    tabs,
  } = useContext(MenuContext);

  const { device } = useContext(CapacitorContext);
  const { organization } = useContext(OrganizationsContext);

  const [showSubMenuTabs, setShowSubMenuTabs] = useState({});

  const toggleTabs = (categoryKey) => {
    setShowSubMenuTabs((prev) => ({
      ...prev,
      [categoryKey]: !prev[categoryKey],
    }));
  };

  const renderCampaignMenu = () => {
    if(getValue(appconfig, "organizations_enabled", "boolean")) {
      if (organization.organization_id && isOrganizationAdmin) {
        return (
          <div
            className="p-2 text-dark hover-accent no-decoration border-top border-bottom"
            key={organizationTabs.campaign?.link}
            onClick={() => {
              toggleMenuButton.current.click();
              navigate(organizationTabs.campaign?.link);
            }}
          >
            <div className="row w-100">
              <div className="col-1">
                <i className={organizationTabs.campaign?.icon} />
              </div>
              <div className="col-11">
                {organizationTabs.campaign?.name[lang]}
              </div>
            </div>
          </div>
        );
      }
    }
    
  };

  const renderHeader = () =>
    selected && (
      <div className="d-inline">
        <span className="ms-2">{selected}</span>
      </div>
    );

  const renderTabs = (tabsItems) =>
    tabsItems.map((tab) => (
      <div
        className="p-2 text-dark hover-accent no-decoration border-top border-bottom"
        key={tab.link}
        onClick={() => {
          toggleMenuButton.current.click();
          navigate(tab.link);
        }}
      >
        <div className="row w-100">
          <div className="col-1">
            <i className={tab.icon} />
          </div>
          <div className="col-11"> {tab.name[lang]}</div>
        </div>
      </div>
    ));

  const renderSubMenuTabs = (tabs) => {
    if (tabs) {
      return Object?.keys(tabs)?.map((categoryKey, index) => {
        const category = tabs[categoryKey];
        const isOpen = showSubMenuTabs[categoryKey] || false;
        const toggleCategory = () => toggleTabs(categoryKey);

        return (
          <div
            key={index}
            style={{ paddingLeft: "12px" }}
            className="text-dark hover-accent no-decoration border-top border-bottom"
          >
            <button
              className="btn text-start text-dark d-flex w-100 ps-2"
              onClick={toggleCategory}
            >
              <div className="col-1">
                <i className={category?.icon} />
              </div>
              <div className="col-11 d-flex justify-content-between">
                {category?.name}{" "}
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </div>
            </button>
            {isOpen && renderTabs(category?.tabs, toggleCategory)}
          </div>
        );
      });
    }
  };

  const renderNormalTabs = (tabs) => renderTabs(tabs);

  const renderOrganizationPicker = () => {
    if (getValue(appconfig, "organizations_enabled", "boolean")) {
      return (
        <OrganizationPickerMobile
          toggleTabs={toggleTabs}
          isOpen={showSubMenuTabs["OrganizationPicker"] || false}
          toggleMenuButton={toggleMenuButton}
        />
      );
    }
  };

  const renderAdminTabs = () => {
    if (
      user.staff &&
      user.staff !== null
      // && organization.name === 'Personal'
    ) {
      return renderSubMenuTabs(adminTabs);
    }
  };

  const renderOrganizationMenu = () => {
    if (getValue(appconfig, "organizations_enabled", "boolean")) {
      if (organization.organization_id && isOrganizationAdmin) {
        return renderSubMenuTabs({
          organization: organizationTabs.organization,
        });
      }
    }
  };

  return (
    <nav
      style={{ top: 32 }}
      className={`show-mobile navbar navbar-expand-xl navbar-light bg-white text-dark fixed-top border-bottom px-3 ${
        hasNotch(device) ? "navbar-notch" : ""
      }`}
    >
      <div className="col-12">
        <div
          style={{ paddingTop: hasNotch(device) ? 50 : 0 }}
          className="row mx-0 align-items-center"
        >
          <div className="col-6 px-0">
            <div className="navbar-brand py-0 me-auto">
              {" "}
              <img
                src={
                  getValue(user, "dark_mode", true)
                    ? getValue(appconfig, "dark_logo")
                    : getValue(appconfig, "light_logo")
                }
                alt="Bunny"
                style={{ maxWidth: 45 }}
                className="d-inline-block"
              />{" "}
              {renderHeader()}
            </div>
          </div>
          <div className="col-6 pe-0 text-end">
            <button
              type="button"
              ref={toggleMenuButton}
              className="navbar-toggler"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>
        <div className="collapse navbar-collapse mw-100 pt-3" id="navbarNav">
          <nav className="navbar-nav me-auto text-start">
            <div
              className="p-2 text-dark hover-accent no-decoration border-top border-bottom"
              onClick={() => {
                toggleMenuButton.current.click();
                navigate("/conversations");
              }}
            >
              <div className="row w-100">
                <i className="fa fa-comment me-1" /> Chats
              </div>
            </div>
            {renderNormalTabs(tabs)}
            {renderCampaignMenu()}
            {renderSubMenuTabs(toolsTabs)}
            {renderOrganizationMenu()}
            {renderAdminTabs()}
            {renderOrganizationPicker()}
            {renderSubMenuTabs(userSettingTabs)}
            <button
              className="btn btn-sm text-start text-danger mx-2 px-0 my-3"
              onClick={signOut}
            >
              <i className="fas fa-sign-out-alt fa-flip-horizontal me-2" /> Log
              Out
            </button>
          </nav>
        </div>
      </div>
    </nav>
  );
};

export default MenuMobile;
