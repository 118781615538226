import React from "react";
import useTranslations from "../../hooks/useTranslations";

const SubmitButton = ({ loading, disabled, onClick }) => {
  const translations = useTranslations();
  return (
    <button
      type="submit"
      onClick={onClick}
      disabled={loading || disabled}
      className="btn w-100 btn-primary"
    >
      {loading ? (
        <div className="spinner-border" />
      ) : (
        <i className="fa fa-save me-2" />
      )}
      {translations.general.save}
    </button>
  );
};

export default SubmitButton;